import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import ConflictDetails from 'duties/roles/conflict-details'
import Accordion from 'react-bootstrap/Accordion'
import { useRoleDuties } from 'api/hooks/use-role-duties'
import { split } from 'lodash'
import InternalConflict from 'duties/badges/internal-conflict'

function Conflicts() {
  const path = window.location.pathname
  const roleId = split(path, '/')[2]
  const [activeKey, setActiveKey] = useState('')

  const { isSuccess, isLoading, data } = useRoleDuties({
    params: { id: roleId }
  })

  return (
    <Card>
      <Card.Header className="bg-white">
        <div className="text-info">
          Internal Conflicts{' '}
          {isSuccess && (
            <InternalConflict count={data.internal_conflicts.length} />
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Accordion activeKey={activeKey} flush>
              {isLoading && (
                <div className="vh-100 d-flex align-items-center justify-content-center">
                  <Spinner animation="grow" variant="warning" />
                </div>
              )}
              {isSuccess && data.analytics.length === 0 && (
                <div>No conflicts found</div>
              )}
              {isSuccess &&
                data.analytics.map((a) => (
                  <Accordion.Item eventKey={`${a.id}`} key={`conflict-${a.id}`}>
                    <Accordion.Header
                      onClick={() =>
                        setActiveKey(activeKey === a.id ? '' : `${a.id}`)
                      }
                    >
                      <h6>
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="text-danger me-2"
                        />
                        {a.name}{' '}
                        <div className="text-muted small mt-1">
                          {a.description}
                        </div>
                      </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ConflictDetails
                        analytic={a}
                        role={data}
                        isActive={activeKey === a.id}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Conflicts
