import React from 'react'
import { formatDateTime } from 'helpers/datetime'
import Link from './event-link'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMinus, faUserPlus, faWrench } from "@fortawesome/free-solid-svg-icons"
import { accountStatus } from './text-helper'
import LogEvent from './LogEvent'

function UserMaster({ log }) {
  const { action, changeable, changed_at, changed_by, field_name, new_value, old_value } = log

  const Icon = () => {
    const iconMap = {
      'A': faUserPlus,
      'D': faUserMinus,
      'default': faWrench
    }
    const icon = iconMap[action] || iconMap['default']
    return <FontAwesomeIcon icon={icon} className='mx-2' />
  }

  const actionText = (action) => {
    switch (action) {
      case 'A':
        return 'Created'
      case 'D':
        return 'Removed'
      case 'U':
        return 'Updated'
      default:
        return action
    }
  }

  const TextContent = () => {
    switch (action) {
      case 'A':
        return 'was created'
      case 'D':
        return 'was deleted'
      case 'U':
        return updatedText()
      default:
        return ''
    }
  }

  const updatedText = () => {
    const fieldMap = {
      'rums_pr_emp_no': `Employee ID was changed to ${new_value}`,
      'rums_acct_status': (
      <>
        Account was changed to {accountStatus(new_value)}
      </>
      ),
      'rums_dept_code': `Department Code was changed from ${old_value} to ${new_value}`
    }
    return fieldMap[field_name] || ''
  }

  return (
    <LogEvent
      title={<><Icon /> User {actionText(log.action)}{' '}</>}
      timestamp={`${formatDateTime(changed_at)} by ${changed_by.external_id}`}
    >
      <span className="ms-2 text-muted fw-light">
        <Link linkObject={changeable} objectType="users" /> {TextContent()}
      </span>
    </LogEvent>
  )
}

export default UserMaster
