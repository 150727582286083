import React from 'react'
import { Form } from 'react-bootstrap'

function AssignedType({ params, setParams }) {
  const handleChange = (event) => {
    setParams({ ...params, assignable_type: event.target.value })
  }

  return (
    <Form.Group controlId="assignedTypeSelect">
      <Form.Select
        as="select"
        value={params.assigned_type}
        onChange={handleChange}
      >
        <option value="">All Approver Types</option>
        <option value="Employee">User</option>
        <option value="Role">Role</option>
      </Form.Select>
    </Form.Group>
  )
}

export default AssignedType
