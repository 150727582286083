import React from 'react'
import { formatDateTime } from 'helpers/datetime'
import { actionText, toFromText } from './text-helper'
import Link from './event-link'
import HighRiskPermissionBadge from 'duties/badges/high-risk-permission'
import SuperUserPermissionBadge from 'duties/badges/superuser-permission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faIdCard,
  faToggleOff,
  faToggleOn,
  faWrench
} from '@fortawesome/free-solid-svg-icons'
import LogEvent from './LogEvent'

function RoleChange({ log }) {
  const Icon = () => {
    if (log.action === 'A') {
      return (
        <>
          <FontAwesomeIcon icon={faToggleOn} />
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    } else if (log.action === 'D') {
      return (
        <>
          <FontAwesomeIcon icon={faToggleOff} />
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faWrench} />
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    }
  }

  const LogText = () => {
    if (log.action === 'U') {
      return (
        <span>
          from <span className="text-bold">{log.old_value}</span> to{' '}
          <span className="text-bold">{log.new_value}</span>
        </span>
      )
    } else if (log.action === 'D') {
      return <span></span>
    } else {
      return (
        <span>
          with a value of <span className="text-muted">{log.new_value}</span>
        </span>
      )
    }
  }

  const LogTitle = () => {
    if (log.action === 'U') {
      return <span>Permission Value Updated</span>
    } else if (log.action === 'A') {
      return <span>Permission Assigned to Role</span>
    } else {
      return <span>Permission Removed from Role</span>
    }
  }

  return (
    <LogEvent
      title={
        <>
          <Icon />
          <LogTitle />
          {log.linkable?.is_high_risk && (
            <HighRiskPermissionBadge count={0} displayCount={false} />
          )}
          {log.linkable?.is_superuser && (
            <SuperUserPermissionBadge count={0} displayCount={false} />
          )}
        </>
      }
      timestamp={`${formatDateTime(log.changed_at)} by ${
        log.changed_by.external_id
      }`}
    >
      <span className="fw-light text-muted">
        {actionText(log.action)}{' '}
        <Link linkObject={log.linkable} objectType="permissions" />{' '}
        {toFromText(log.action)}{' '}
        <Link linkObject={log.changeable} objectType="roles" /> <LogText />
      </span>
    </LogEvent>
  )
}

export default RoleChange
