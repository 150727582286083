import React from 'react'
import { Modal, Table } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import orderBy from 'lodash/orderBy'
import { useNavigate } from 'react-router-dom'

function FunctionalCategories({ setActiveTab, setShow, show, employee }) {
  const navigate = useNavigate()
  const handleClose = () => setShow(false)

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Functional Categories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>Function</th>
              <th>Permission Count</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {orderBy(
              Object.entries(employee.functional_categories),
              [1],
              ['desc']
            ) // Sort by values in descending order
              .map(([key, value], index) => (
                <>
                  <tr key={index}>
                    <td>{key}</td>
                    <td>{value}</td>
                    <td>
                      <Button
                        className="btn-sm"
                        onClick={() => {
                          setShow(false)
                          setActiveTab('permissions')
                          navigate(`?category=${key}`) // Add the category key to the URL
                        }}
                        variant="link"
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default FunctionalCategories