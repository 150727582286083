import React, { useState, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import {
  useCreateDashboardColumn,
  useDeleteDashboardColumn
} from 'api/hooks/use-dashboard-columns'
import { useQueryClient } from 'react-query'
import UserContext from 'context/user-context'

function UpdateColumn({ column, dashboardId, currentColumns }) {
  const queryClient = useQueryClient()
  const { municipality } = useContext(UserContext)

  const {
    seg_one_name,
    seg_two_name,
    seg_three_name,
    seg_four_name,
    seg_five_name,
    seg_six_name,
    seg_seven_name,
    seg_eight_name
  } = municipality.setting
  const [isChecked, setIsChecked] = useState(() => 
    currentColumns.map((c) => c.id).includes(column.id)
  )
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(`dashboards/${dashboardId}`)
    }
  }
  const params = {
    dashboard_id: dashboardId,
    table_column_id: column.id
  }

  const { mutate: addColumn } = useCreateDashboardColumn({ params, options })
  const { mutate: deleteColumn } = useDeleteDashboardColumn({
    id: currentColumns.find(
      (c) => c.id === column.id
    )?.dashboard_column_id,
    options
  })

  const handleToggle = (e) => {
    setIsChecked(e.target.checked)
    if (e.target.checked) {
      addColumn()
    } else {
      deleteColumn()
    }
  }
  const columnName = (column) => {
    switch (column.database_column_name) {
      case 'seg_one':
        return seg_one_name
      case 'seg_two':
        return seg_two_name
      case 'seg_three':
        return seg_three_name
      case 'seg_four':
        return seg_four_name
      case 'seg_five':
        return seg_five_name
      case 'seg_six':
        return seg_six_name
      case 'seg_seven':
        return seg_seven_name
      case 'seg_eight':
        return seg_eight_name
      default:
        return column.name
    }
  }

  return (
    <div key={`check-${column.id}`} className="mt-2">
      <Form.Check
        type="checkbox"
        label={
          <>
            {columnName(column)}
          </>
        }
        id={column.id}
        onChange={handleToggle}
        checked={isChecked}
      />
    </div>
  )
}

export default UpdateColumn
