import React from 'react'
import Badge from 'react-bootstrap/Badge'
import {
  faUserSlash,
  faUserMinus,
  faUnlink
} from '@fortawesome/free-solid-svg-icons'
import StatusBadge from '../../shared/badges/status-badge'

function UserBadges({ user }) {
  const convertToDaysSince = (date) => {
    const date1 = new Date(date)
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }

  return (
    <>
      {user.rules_count > 0 && (
        <Badge
          pill
          className={'text-center fw-normal bg-light text-primary mx-1'}
        >
          {user.rules_count} Business Rule(s)
        </Badge>
      )}
      {user.status === 'inactive' ? (
        <span className="ms-2">
          <StatusBadge
            title="Disabled"
            icon={faUserSlash}
            tooltipText="Account is not active"
          />
        </span>
      ) : null}
      {user.status === 'active' && user.is_terminated ? (
        <span className="ms-2">
          <StatusBadge
            title="Terminated"
            icon={faUserMinus}
            tooltipText={`Employee account is active but employee has been terminated for ${convertToDaysSince(
              user.terminated_on
            )} days`}
          />
        </span>
      ) : null}
      {user.missing_payroll_record && user.status === 'active' && (
        <span className="ms-2">
          <StatusBadge
            title="Payroll"
            icon={faUnlink}
            tooltipText="No payroll record found. Add the employee ID to the user record to ensure the user is linked to a payroll record."
          />
        </span>
      )}
    </>
  )
}

export default UserBadges
