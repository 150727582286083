import React, { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClipboard,
  faAngleRight,
  faAngleLeft
} from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { findIndex, isEmpty } from 'lodash'
import { useQueryClient } from 'react-query'
import { toast, Flip } from 'react-toastify'

function DrawerHeader({
  transactions,
  setCurrentTransaction,
  currentTransaction,
  transaction,
  handleClose
}) {
  const [currentIndex, setCurrentIndex] = useState(
    findIndex(transactions, currentTransaction)
  )
  const queryClient = useQueryClient()
  const [nextId, setNextId] = useState(transactions[currentIndex + 1])
  const [previousId, setPreviousId] = useState(transactions[currentIndex - 1])
  const [showButton, setShowButton] = useState('')
  const hasPrevious = currentIndex > 0
  const hasNext = currentIndex < transactions.length - 1

  const copiedAlert = (message) => {
    toast.info(`Copied ${message} to clipboard`, {
      position: 'top-right',
      autoClose: 1000,
      transition: Flip,
      hideProgressBar: true,
      progress: undefined,
      theme: 'light'
    })
  }

  useEffect(() => {
    const index = transactions.findIndex((t) => t === currentTransaction)
    setCurrentIndex(index)
    setNextId(transactions[currentIndex + 1])
    setPreviousId(transactions[currentIndex - 1])
  }, [currentTransaction, currentIndex, transactions])

  return (
    <>
      <Offcanvas.Header className="sticky d-flex flex-row justify-content-between">
        <div className="d-flex fw-lighter">{transaction.section_name}</div>
        <div className="d-flex">
        </div>
        <div className="d-flex fw-light">
          <small className="fw-light mt-1 me-2 text-muted">
            {currentIndex + 1} of {transactions.length}
          </small>
          <Button
            size="sm"
            variant={hasPrevious ? 'light' : 'outline-secondary'}
            onClick={() => {
              queryClient.invalidateQueries(
                `transactions/${currentTransaction.id}/reviews`
              )
              setCurrentTransaction(previousId)
            }}
            disabled={hasPrevious ? false : true}
            className="mx-1"
          >
            <FontAwesomeIcon icon={faAngleLeft} className="mx-1 mt-1" />
          </Button>
          <Button
            size="sm"
            variant={hasNext ? 'light' : 'outline-secondary'}
            onClick={() => {
              queryClient.invalidateQueries(
                `transactions/${currentTransaction.id}/reviews`
              )
              setCurrentTransaction(nextId)
            }}
            disabled={hasNext ? false : true}
            className="mx-1"
          >
            <FontAwesomeIcon icon={faAngleRight} className="mx-1 mt-1" />
          </Button>
          <Button
            variant="outline-info"
            size="sm"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Header className="sticky d-flex flex-row pt-0">
        <div className="d-flex">
          {isEmpty(transaction.section_display_key) ? (
            transaction.external_id
          ) : (
            <React.Fragment>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(transaction.display_key)
                  copiedAlert(transaction.section_display_key)
                }}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setShowButton('display-key')}
                onMouseLeave={() => setShowButton('')}
              >
                <span className="text-muted fw-light me-1">
                  {transaction.section_display_key}
                </span>
                <span>{transaction.display_key}</span>
                {showButton === 'display-key' ? (
                  <FontAwesomeIcon icon={faClipboard} className="ms-1" />
                ) : (
                  <FontAwesomeIcon
                    icon={faClipboard}
                    className="ms-1 text-white"
                  />
                )}
              </div>

              {!isEmpty(transaction.section_display_key_two) && (
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(transaction.display_key_two)
                    copiedAlert(transaction.section_display_key_two)
                  }}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setShowButton('display-key-two')}
                  onMouseLeave={() => setShowButton('')}
                >
                  <span className="text-muted fw-light ms-4 me-1">
                    {transaction.section_display_key_two}
                  </span>
                  <span>{transaction.display_key_two}</span>
                  {showButton === 'display-key-two' ? (
                    <FontAwesomeIcon icon={faClipboard} className="ms-1" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faClipboard}
                      className="ms-1 text-white"
                    />
                  )}
                </div>
              )}
              <div
                onClick={() => {
                  navigator.clipboard.writeText(transaction.external_id)
                  copiedAlert('ThirdLine ID')
                }}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setShowButton('thirdline-id')}
                onMouseLeave={() => setShowButton('')}
              >
                <span className="text-muted fw-light ms-4 me-1">
                  ThirdLine ID
                </span>
                <span>{transaction.external_id}</span>
                {showButton === 'thirdline-id' && (
                  <FontAwesomeIcon icon={faClipboard} className="ms-1" />
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </Offcanvas.Header>
    </>
  )
}

export default DrawerHeader
