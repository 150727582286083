import React from 'react'

function MissingPayroll({ params, setParams }) {
  return (
    <div className="form-check form-switch mt-1">
      <input
        className="form-check-input"
        type="checkbox"
        id="missingPayroll"
        checked={params.missingPayroll}
        onChange={() =>
          setParams({ ...params, missingPayroll: !params.missingPayroll })
        }
      />
      <label className="form-check-label" htmlFor="missingPayroll">
        Missing PR
      </label>
    </div>
  )
}

export default MissingPayroll
