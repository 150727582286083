import { useEffect, useState, useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Col from 'react-bootstrap/Col'
import UserContext from 'context/user-context'
import Tab from 'dashboard/tab'
import TopTransactions from '../top-transactions'
import Card from 'react-bootstrap/Card'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'
import useSections from 'api/hooks/use-sections'
import DashboardTitle from '../title'
import { useDashboard } from 'api/hooks/use-dashboards'
import DashboardFilters from '../filters'
import DashboardPopulation from '../population'
import Button from 'react-bootstrap/Button'
import { useUpdateDashboard, useDashboards } from 'api/hooks/use-dashboards'
import { useNavigate } from 'react-router-dom'
import NewView from 'dashboard/new'
import SectionDropdown from 'dashboard/filters/module-dropdown'

function PoDashboard() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const id = urlParams.get('dashboard_id')
  const navigate = useNavigate()
  const { po_dashboards } = useContext(UserContext)
  const dashboardUrlId = id || po_dashboards[0].id

  useEffect(() => {
    if (!id) {
      const newUrl = `${window.location.pathname}?dashboard_id=${dashboardUrlId}`
      navigate(newUrl, { replace: true })
    }
  }, [id, dashboardUrlId, navigate])
  
  const [dashboardId, setDashboardId] = useState(dashboardUrlId)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [showStats, setShowStats] = useState(true)
  const { isSuccess: isSectionSuccess, data: sections } = useSections({
    options: {}
  })

  const defaultSection = isSectionSuccess && sections[0]
  const options = {
    enabled: !!dashboardId && !isFilterOpen
  }

  const sectionId = po_dashboards[0].section_id

  const { isSuccess: isDashboardsSuccess, data: dashboards } = useDashboards({
    params: { section_id: sectionId }
  })
  const {
    data: dashboard,
    isSuccess: isDashboardLoaded,
    isLoading: isDashboardLoading
  } = useDashboard({
    id: dashboardId,
    options
  })
  
  const defaultParams = {
    dashboardId: dashboardId,
    name: defaultSection.name || '',
    risk_threshold: 0,
    section_id: defaultSection.id || '',
    minimum_amount: '',
    maximum_amount: '',
    vendors: [],
    employees: [],
    analytics: [],
    account_search: '',
    orgs: [],
    objects: [],
    projects: [],
    segOnes: [],
    segTwos: [],
    segThrees: [],
    segFours: [],
    segFives: [],
    segSixes: [],
    segSevens: [],
    segEights: [],
    poIsPendingWorkflow: '',
    poMinReqToPoCreation: '',
    poStatusCode: '',
    poMinPoLiquidatedAmount: '',
    poMaxPoLiquidatedAmount: '',
    poMinPoRemainingAmount: '',
    poMaxPoRemainingAmount: '',
    poMinPoPercentLiquidated: '',
    poMaxPoPercentLiquidated: '',
    poReqPreparedDateOption: '',
    poMinReqPreparedDate: '',
    poMaxReqPreparedDate: '',
    poPoEntryDateOption: '',
    poMinPoEntryDate: '',
    poMaxPoEntryDate: '',
    poPoChangeDateOption: '',
    poMinPoChangeDate: '',
    poMaxPoChangeDate: '',
    poPoGlEffectiveDateOption: '',
    poMinPoGlEffectiveDate: '',
    poMaxPoGlEffectiveDate: '',
    poPoPostingDateOption: '',
    poMinPoPostingDate: '',
    poMaxPoPostingDate: ''
  }
  const [params, setParams] = useState({ ...defaultParams })

  const sectionName = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.name
    : ''
  const sectionUnit = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.unit
    : ''

  useEffect(() => {
    if (isDashboardLoaded && !!dashboardId) {
      setParams((params) => ({
        ...params,
        name: dashboard?.name,
        section_id: dashboard?.section_id,
        dashboardId: dashboardId,
        minimum_amount: dashboard?.minimum_amount || '',
        maximum_amount: dashboard?.maximum_amount || '',
        vendors: dashboard?.vendors || [],
        employees: dashboard?.employees || [],
        analytics: dashboard?.analytics || [],
        orgs: dashboard?.account_search?.orgs || [],
        objects: dashboard?.account_search?.objects || [],
        projects: dashboard?.account_search?.projects || [],
        segOnes: dashboard?.account_search?.seg_ones || [],
        segTwos: dashboard?.account_search?.seg_twos || [],
        segThrees: dashboard?.account_search?.seg_threes || [],
        segFours: dashboard?.account_search?.seg_fours || [],
        segFives: dashboard?.account_search?.seg_fives || [],
        segSixes: dashboard?.account_search?.seg_sixes || [],
        segSevens: dashboard?.account_search?.seg_sevens || [],
        segEights: dashboard?.account_search?.seg_eights || [],
        poIsPendingWorkflow: dashboard?.params?.poIsPendingWorkflow || false,
        poMinReqToPoCreation: dashboard?.params?.poMinReqToPoCreation || '',
        poStatusCode: dashboard?.params?.poStatusCode || '',
        poMinPoLiquidatedAmount: dashboard?.params?.poMinPoLiquidatedAmount || '',
        poMaxPoLiquidatedAmount: dashboard?.params?.poMaxPoLiquidatedAmount || '',
        poMinPoRemainingAmount: dashboard?.params?.poMinPoRemainingAmount || '',
        poMaxPoRemainingAmount: dashboard?.params?.poMaxPoRemainingAmount || '',
        poMinPoPercentLiquidated: dashboard?.params?.poMinPoPercentLiquidated || '',
        poMaxPoPercentLiquidated: dashboard?.params?.poMaxPoPercentLiquidated || '',
        poReqPreparedDateOption: dashboard?.params?.poReqPreparedDateOption || '',
        poMinReqPreparedDate: dashboard?.params?.poMinReqPreparedDate || '',
        poMaxReqPreparedDate: dashboard?.params?.poMaxReqPreparedDate || '',
        poPoEntryDateOption: dashboard?.params?.poPoEntryDateOption || '',
        poMinPoEntryDate: dashboard?.params?.poMinPoEntryDate || '',
        poMaxPoEntryDate: dashboard?.params?.poMaxPoEntryDate || '',
        poPoChangeDateOption: dashboard?.params?.poPoChangeDateOption || '',
        poMinPoChangeDate: dashboard?.params?.poMinPoChangeDate || '',
        poMaxPoChangeDate: dashboard?.params?.poMaxPoChangeDate || '',
        poPoGlEffectiveDateOption: dashboard?.params?.poPoGlEffectiveDate || '',
        poMinPoGlEffectiveDate: dashboard?.params?.poMinPoGlEffectiveDate || '',
        poMaxPoGlEffectiveDate: dashboard?.params?.poMaxPoGlEffectiveDate || '',
        poPoPostingDateOption: dashboard?.params?.poPoPostingDateOption || '',
        poMinPoPostingDate: dashboard?.params?.poMinPoPostingDate || '',
        poMaxPoPostingDate: dashboard?.params?.poMaxPoPostingDate || ''
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardLoaded, dashboardId, setParams])

  const dashboardSettings = {
    params: {
      poIsPendingWorkflow: params.poIsPendingWorkflow ? true : '',
      poMinReqToPoCreation: params.poMinReqToPoCreation,
      poStatusCode: params.poStatusCode,
      poMinPoLiquidatedAmount: params.poMinPoLiquidatedAmount,
      poMaxPoLiquidatedAmount: params.poMaxPoLiquidatedAmount,
      poMinPoRemainingAmount: params.poMinPoRemainingAmount,
      poMaxPoRemainingAmount: params.poMaxPoRemainingAmount,
      poMinPoPercentLiquidated: params.poMinPoPercentLiquidated,
      poMaxPoPercentLiquidated: params.poMaxPoPercentLiquidated,
      poReqPreparedDateOption: params.poReqPreparedDateOption,
      poMinReqPreparedDate: params.poMinReqPreparedDate,
      poMaxReqPreparedDate: params.poMaxReqPreparedDate,
      poPoEntryDateOption: params.poPoEntryDateOption,
      poMinPoEntryDate: params.poMinPoEntryDate,
      poMaxPoEntryDate: params.poMaxPoEntryDate,
      poPoChangeDateOption: params.poPoChangeDateOption,
      poMinPoChangeDate: params.poMinPoChangeDate,
      poMaxPoChangeDate: params.poMaxPoChangeDate,
      poPoGlEffectiveDateOption: params.poPoGlEffectiveDate,
      poMinPoGlEffectiveDate: params.poMinPoGlEffectiveDate,
      poMaxPoGlEffectiveDate: params.poMaxPoGlEffectiveDate,
      poPoPostingDateOption: params.poPoPostingDateOption,
      poMinPoPostingDate: params.poMinPoPostingDate,
      poMaxPoPostingDate: params.poMaxPoPostingDate
    }
  }

  const { mutate } = useUpdateDashboard({
    params: dashboardSettings,
    id: dashboardId
  })

  useEffect(() => {
    if (isDashboardLoaded && !!dashboardId) {
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params?.poIsPendingWorkflow,
    params?.poMinReqToPoCreation,
    params?.poStatusCode,
    params?.poMinPoLiquidatedAmount,
    params?.poMaxPoLiquidatedAmount,
    params?.poMinPoRemainingAmount,
    params?.poMaxPoRemainingAmount,
    params?.poMinPoPercentLiquidated,
    params?.poMaxPoPercentLiquidated,
    params?.poReqPreparedDateOption,
    params?.poMinReqPreparedDate,
    params?.poMaxReqPreparedDate,
    params?.poPoEntryDateOption,
    params?.poMinPoEntryDate,
    params?.poMaxPoEntryDate,
    params?.poPoChangeDateOption,
    params?.poMinPoChangeDate,
    params?.poMaxPoChangeDate,
    params?.poPoGlEffectiveDateOption,
    params?.poMinPoGlEffectiveDate,
    params?.poMaxPoGlEffectiveDate,
    params?.poPoPostingDateOption,
    params?.poMinPoPostingDate,
    params?.poMaxPoPostingDate
  ])

  return (
    <Page
      title={
        <Row>
          <Col sm="auto">
            <SectionDropdown sectionId={sectionId} size="" />
            {false && isDashboardLoaded && (
              <DashboardTitle params={params} setParams={setParams} />
            )}
          </Col>
          <Col sm="auto"></Col>
        </Row>
      }
      pageTitle={`View - ${isDashboardLoaded ? dashboard?.name : ''}`}
    >
      <PageTitle />

      <Row className="my-4">
        <Col>
          <ErrorBoundary
            fallback={DefaultError}
            beforeCapture={(scope) => {
              scope.setTag('component', 'DashboardPopulation')
            }}
          >
            {false && (
              <DashboardPopulation
                params={params}
                setParams={setParams}
                sectionUnit={sectionUnit}
                sectionName={sectionName}
                isFilterOpen={isFilterOpen}
              />
            )}
          </ErrorBoundary>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <>
            <Row className="mb-3 border-bottom">
              <Col>
                {isDashboardsSuccess &&
                  dashboards
                    .slice(0, 6)
                    .map((d) => (
                      <Tab
                        active={d.id === dashboardId}
                        key={`tab-${d.id}`}
                        setDashboardId={setDashboardId}
                        dashboard={d}
                        isDashboardLoading={isDashboardLoading}
                        params={params}
                        setParams={setParams}
                      />
                    ))}
                <Button
                  className="py-2 px-3 border-left border-right border-light rounded-0 my-0"
                  variant="link"
                  style={{ backgroundColor: '#f7f7f7' }}
                  onClick={() => navigate('/dashboards')}
                >
                  All Views
                </Button>
                <div className="float-end">
                  <NewView sectionId={sectionId} sectionName={sectionName} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <DashboardFilters
                  params={params}
                  setParams={setParams}
                  isFilterOpen={isFilterOpen}
                  setIsFilterOpen={setIsFilterOpen}
                  sectionName={sectionName}
                  showStats={showStats}
                  setShowStats={setShowStats}
                  includedFilters={['employee', 'vendor', 'analytics', 'more']}
                />
              </Col>
            </Row>
            {isSectionSuccess && (
              <>
                <Row className="mt-4">
                  <Col>
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'TopTransactions')
                      }}
                    >
                      <TopTransactions
                        params={params}
                        sectionUnit={sectionUnit}
                        sectionName={sectionName}
                        dashboardId={dashboardId}
                        dashboard={dashboard}
                        isFilterOpen={isFilterOpen}
                      />
                    </ErrorBoundary>
                  </Col>
                </Row>
              </>
            )}
          </>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default PoDashboard
