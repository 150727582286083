import React from 'react'

const StepNumber = ({ params, setParams }) => {
  const handleChange = (e) => {
    setParams({ ...params, stepNumber: e.target.value })
  }

  return (
    <div>
      <input
        type="text"
        id="stepNumber"
        className="form-control"
        placeholder="Step #"
        value={params.step_number || ''}
        onChange={handleChange}
      />
    </div>
  )
}

export default StepNumber
