import React, { useState } from 'react'
import EmptyPage from 'layout/sod/empty-page'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import { split } from 'lodash'
import { usePermission } from 'api/hooks/use-permissions'
import SuperUserPermissionBadge from 'duties/badges/superuser-permission'
import HighRiskPermissionBadge from 'duties/badges/high-risk-permission'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import EmployeesTab from 'duties/permissions/employees-tab'
import RolesTab from 'duties/permissions/roles-tab'
import AuditLogTab from 'duties/permissions/audit-log-tab'

function Permission() {
  const path = window.location.pathname
  const permissionId = split(path, '/')[2]
  const [activeTab, setActiveTab] = useState('employees')

  const params = {
    id: permissionId
  }

  const { isSuccess, isLoading, data: permission } = usePermission({ params })

  if (isLoading) {
    return <EmptyPage />
  }

  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Permissions', url: 'permissions' },
    { name: isSuccess ? permission.name : '', url: '' }
  ]

  return (
    <Page title={`Permission`} subtitle={''}>
      <PageTitle breadcrumbs={crumbs} />
      {isSuccess && (
        <>
          <h4 className="my-4">{permission.name}</h4>

          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={2}>
                      <span className="fw-bold">Functional Area</span>
                      <div className="fw-light mt-2">
                        {permission.category_caption}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="fw-bold">Description</div>
                      <div className="fw-light mt-2">
                        {permission.description}
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="fw-bold mb-2">Tags</div>
                      {!permission.is_superuser && !permission.is_high_risk && (
                        <span className="text-muted">None</span>
                      )}
                      {permission.is_superuser && (
                        <SuperUserPermissionBadge
                          count={1}
                          displayCount={false}
                        />
                      )}
                      {permission.is_high_risk && (
                        <HighRiskPermissionBadge
                          count={1}
                          displayCount={false}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col sm={8}>
                      <div className="fw-bold mb-2">Additional Details</div>
                      <span className="text-muted">
                        {permission.long_description}
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row className="mt-4">
                <div className="mb-4">
                  <Button
                    variant={activeTab === 'employees' ? 'primary' : 'light'}
                    onClick={() => setActiveTab('employees')}
                    className="ms-2"
                  >
                    Employees
                  </Button>
                  <Button
                    variant={activeTab === 'roles' ? 'primary' : 'light'}
                    onClick={() => setActiveTab('roles')}
                    className="ms-2"
                  >
                    Roles
                  </Button>
                  <Button
                    variant={activeTab === 'audit' ? 'primary' : 'light'}
                    onClick={() => setActiveTab('audit')}
                    className="ms-2"
                  >
                    Audit Log
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        </>
      )}

      {activeTab === 'employees' && <EmployeesTab permission={permission} />}

      {activeTab === 'roles' && <RolesTab permission={permission} />}

      {activeTab === 'audit' && <AuditLogTab permission={permission} />}
    </Page>
  )
}

export default Permission
