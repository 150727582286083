import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getWorkflowProcessTypes = async (token, params) => {
  const url = 'workflow_process_types'
  const data = await apiGet({ url, token, params })
  return data
}

export default function useWorkflowProcessTypes({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = ['workflow-process-types', params]
  const queryFn = () => getWorkflowProcessTypes(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
