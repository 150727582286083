import { Badge } from 'react-bootstrap'

export const actionText = (action) => {
  switch (action) {
    case 'A':
      return 'Assigned'
    case 'D':
      return 'Removed'
    case 'U':
      return 'Updated'
    default:
      return action
  }
}

export const toFromText = (action) => {
  switch (action) {
    case 'A':
      return 'to'
    case 'D':
      return 'from'
    case 'U':
      return 'for'
    default:
      return action
  }
}

export const accountStatus = (status) => {
  const statusMap = {
    0: { color: 'darkgray', text: 'Inactive' },
    1: { color: '#27AB83', text: 'Active' }
  }
  const { color, text } = statusMap[status] || {
    color: 'lightgray',
    text: 'Unknown'
  }
  return (
    <Badge pill bg="light" className="text-primary">
      <span
        className="dot"
        style={{
          backgroundColor: color,
          borderRadius: '50%',
          display: 'inline-block',
          width: '8px',
          height: '8px',
          marginRight: '5px'
        }}
      ></span>
      {text}
    </Badge>
  )
}