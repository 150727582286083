import React from 'react'
import NumberField from 'shared/forms/NumberField'

function MinTotalDaysToPayment({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Min Invoice Date to Check Date (Days)"
      showTitle={true}
      accessorKey="apMinTotalDaysToPayment"
    />
  )
}

export default MinTotalDaysToPayment
