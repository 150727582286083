import React, { useContext, useState } from 'react'
import { usePendingWorkflows } from 'api/hooks/use-pending-workflows'
import moment from 'moment'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import FilterContext from 'context/filters-context'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProcessFilter from 'workflow/filters/process'
import Export from 'workflow/filters/export'


function Pending() {
  const { filters } = useContext(FilterContext)
  const defaultParams = {
    page: 1,
    perPage: 25,
    orderBy: 'action_at',
    orderDirection: 'desc',
    processes: filters?.processes || []
  }
  const [activeOverlay, setActiveOverlay] = useState('')
  const [params, setParams] = useState(defaultParams)

  const { isLoading, isSuccess, data } = usePendingWorkflows({ params })

  const mapValues = (process) => {
    if (process === 'API') {
      return 'Inv #:'
    }
    if (process === 'REQ') {
      return 'Req #:'
    }
    if (process === 'POM') {
      return 'PO Change:'
    }
    if (process === 'POE') {
      return 'PO:'
    }
  }

  const displayKey = (workflow) => {
    if (workflow.process === 'API') {
      return workflow.display_key_two
    }
    if (workflow.process === 'REQ') {
      return workflow.display_key
    }
    if (workflow.process === 'POM') {
      return workflow.display_key_two
    }
    if (workflow.process === 'POE') {
      return workflow.display_key_two
    }
  }

  return (
    <div>
      <div>
        <Card>
          <Card.Body>
            <Row>
              <Col className="my-auto mx-2" sm="auto">
                <h6 className="text-muted mt-1">Filters</h6>
              </Col>
              <Col sm={'auto'}>
                <ProcessFilter
                  params={params}
                  setParams={setParams}
                  activeOverlay={activeOverlay}
                  setActiveOverlay={setActiveOverlay}
                />
              </Col>
              <Col sm={'auto'}>
                <Export
                  params={params}
                  setParams={setParams}
                  source="pendingWorkflow"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <Table className="border bg-white border-light my-4" responsive>
        <thead>
          <tr>
            <th>Process Type</th>
            <th>Step</th>
            <th>Approver</th>
            <th>Approval Type</th>
            <th>Time in Queue</th>
            <th>Entered Queue On</th>
            <th>Hold</th>
          </tr>
        </thead>
        {isLoading && <TableLoading columns={6} />}
        {isSuccess && data.data.length === 0 && (
          <tr>
            <td colSpan={7}>
              <div className="d-flex justify-content-center">
                <h5>No pending workflows found</h5>
              </div>
            </td>
          </tr>
        )}
        {isSuccess && data.data.length > 0 && (
          <tbody>
            {data.data.map((w, i) => (
              <tr key={`${displayKey(w)}-${w.approver_external_id}-${i}`}>
                <td>
                  {mapValues(w.process)} {displayKey(w)}
                  <small className="ms-2 text-muted">
                    {w?.subtext ? `(${w.subtext})` : ''}
                  </small>
                </td>
                <td>{w.step_number}</td>
                <td>{w.approver_external_id}</td>
                <td>{w.approval_type}</td>
                <td>{w.entered_at ? w.time_in_queue_in_words : ''}</td>
                <td className="text-muted fw-light">
                  {w.entered_at &&
                    moment(w.entered_at, 'YYYY-MM-DD').format('L')}
                </td>
                <td className="text-muted fw-light">
                  {w.pending_hold_at
                    ? moment(w.pending_hold_at, 'YYYY-MM-DD').format('L')
                    : ''}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>

      {isSuccess && (
        <TableFooter meta={data.meta} params={params} setParams={setParams} />
      )}
    </div>
  )
}

export default Pending
