import React, { useState, useEffect } from 'react'
import { Col, Card, Row } from 'react-bootstrap'
import StartOn from './StartOn'
import EndOn from './EndOn'
import ChangedBy from './ChangedBy'

function Filters({ params, setParams }) {
  const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    const filtersApplied = ['changeType', 'startOn', 'endOn', 'changedById'].some((key) => {
      console.log(params[key])
      return params[key] !== ''
    })
    setIsFiltered(filtersApplied)
  }, [params.changeType, params.startOn, params.endOn, params.changedById, params])

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col sm="auto">
              <select
                value={params.changeType}
                onChange={(e) =>
                  setParams({ ...params, changeType: e.target.value })
                }
                className="form-select"
              >
                <option value="">All Change Types</option>
                <option value="User Master">Users</option>
                <option value="Role Assignment">Role Assignments</option>
                <option value="Role Master">Roles</option>
                <option value="Role Change">Role Permissions</option>
              </select>
            </Col>
            <Col sm="auto">
              <StartOn params={params} setParams={setParams} />
            </Col>
            <Col sm="auto">
              <EndOn params={params} setParams={setParams} />
            </Col>
            <Col sm="auto">
              <ChangedBy params={params} setParams={setParams} />
            </Col>
            {isFiltered && (
              <Col sm="auto">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() =>
                    setParams({
                      changeType: '',
                      startOn: '',
                      endOn: '',
                      changedById: ''
                    })
                  }
                >
                  Clear Filters
                </button>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default Filters
