import React from 'react'
import { faHome, faUsers, faUserSlash, faAddressCard, faToggleOn, faRandom } from '@fortawesome/free-solid-svg-icons'
import SidebarLink from './sidebar-link'

function Sidebar() {
  return (
    <nav className="col-md-2 d-none d-md-block sidebar bg-light">
      <div className="sidebar-sticky">
        <ul className="nav flex-column mt-2">
          <SidebarLink path={'duties'} url="duties" title="Home" icon={faHome} />
          {/* Conflicts, Roles, Users, Permissions */}
          <SidebarLink
            path={'conflicts'}
            url="conflicts"
            title="Conflicts"
            icon={faUserSlash}
          />

          <SidebarLink
            path={'roles'}
            url="roles"
            title="Roles"
            icon={faAddressCard}
          />

          <SidebarLink
            path={'users'}
            url="users"
            title="Users"
            icon={faUsers}
          />

          <SidebarLink
            path={'permissions'}
            url="permissions"
            title="Permissions"
            icon={faToggleOn}
          />

          <SidebarLink
            path={'business-rules'}
            url="business-rules"
            title="Business Rules"
            icon={faRandom}
          />
        </ul>
      </div>
    </nav>
  )
}

export default Sidebar
