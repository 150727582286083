import React from 'react'
import { trackEvent } from '../../../../google-analytics'
import { useNavigate } from 'react-router-dom'

function EventLink({ linkObject, objectType }) {
  const url = `/${objectType}/${linkObject?.id}`
  const navigate = useNavigate()
  const onClick = () => {
    trackEvent({
      category: 'Audit Log',
      action: 'click',
      value: objectType
    })
    navigate(url)
  }

  const text = (objectType) => {
    switch (objectType) {
      case 'users':
        return linkObject.external_id
      case 'roles':
        return linkObject.name
      case 'permissions':
        return linkObject.description
      default:
        return linkObject?.name
    }
  }

  if (linkObject) {
    return (
      <button
        rel="noreferrer"
        onClick={onClick}
        className="btn btn-link px-0 py-0.5"
      >
        {text(objectType)}
      </button>
    )
  } else {
    return <span>Unknown {objectType}</span>
  }
}

export default EventLink
