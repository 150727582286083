import React from 'react'
import { Form } from 'react-bootstrap'

function RuleType({ params, setParams }) {
  const handleChange = (event) => {
    setParams({ ...params, rule_type: event.target.value })
  }

  return (
    <Form.Group controlId="ruleTypeSelect">
      <Form.Select as="select" value={params.rule_type} onChange={handleChange}>
        <option value="">All Rule Types</option>
        <option value="A">Approval</option>
        <option value="N">Notification</option>
      </Form.Select>
    </Form.Group>
  )
}

export default RuleType
