import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getPermissionCategories = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/permission_categories`
  const data = await apiGet({ url, params, token })
  return data
}

export function usePermissionCategories({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryFn = () => getPermissionCategories(token, params, municipality_id)
  const queryKey = ['permission-categories']
  return useQuery(queryKey, queryFn, { ...options })
}