import { useEffect, useState, useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Col from 'react-bootstrap/Col'
import UserContext from 'context/user-context'
import TopTransactions from '../top-transactions'
import Card from 'react-bootstrap/Card'
import Tab from 'dashboard/tab'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'
import useSections from 'api/hooks/use-sections'
import DashboardTitle from '../title'
import { useDashboard } from 'api/hooks/use-dashboards'
import DashboardFilters from '../filters'
import DashboardPopulation from '../population'
import Button from 'react-bootstrap/Button'
import { useUpdateDashboard, useDashboards } from 'api/hooks/use-dashboards'
import { useNavigate } from 'react-router-dom'
import NewView from 'dashboard/new'
import SectionDropdown from 'dashboard/filters/module-dropdown'

function BgDashboard() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const id = urlParams.get('dashboard_id')
  const navigate = useNavigate()
  const { bg_dashboards } = useContext(UserContext)
  const dashboardUrlId = id || bg_dashboards[0].id

  useEffect(() => {
    if (!id) {
      const newUrl = `${window.location.pathname}?dashboard_id=${dashboardUrlId}`
      navigate(newUrl, { replace: true })
    }
  }, [id, dashboardUrlId, navigate])

  const [dashboardId, setDashboardId] = useState(dashboardUrlId)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [showStats, setShowStats] = useState(true)
  const { isSuccess: isSectionSuccess, data: sections } = useSections({
    options: {}
  })

  const defaultSection = isSectionSuccess && sections[0]
  const options = {
    enabled: !!dashboardId && !isFilterOpen
  }

  const sectionId = bg_dashboards[0].section_id

  const { isSuccess: isDashboardsSuccess, data: dashboards } = useDashboards({
    params: { section_id: sectionId }
  })
  const {
    data: dashboard,
    isLoading: isDashboardLoading,
    isSuccess: isDashboardLoaded
  } = useDashboard({
    id: dashboardId,
    options
  })
  const defaultParams = {
    name: defaultSection.name || '',
    risk_threshold: 0,
    section_id: defaultSection.id || ''
  }
  const [params, setParams] = useState({
    ...defaultParams,
    dashboardId: dashboardId,
    minimum_amount: '',
    maximum_amount: '',
    vendors: [],
    employees: [],
    analytics: [],
    account_search: '',
    orgs: [],
    objects: [],
    projects: [],
    segOnes: [],
    segTwos: [],
    segThrees: [],
    segFours: [],
    segFives: [],
    segSixes: [],
    segSevens: [],
    segEights: [],
    bgBudgetYear: '',
    bgMinBudgetPercentOfOriginal: '',
    bgMaxBudgetPercentOfOriginal: '',
    bgMinBudgetPercentRemaining: '',
    bgMaxBudgetPercentRemaining: '',
    bgAccountLastUpdateOption: '',
    bgMinAccountLastUpdate: '',
    bgMaxAccountLastUpdate: ''
  })

  const sectionName = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.name
    : ''
  const sectionUnit = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.unit
    : ''

  useEffect(() => {
    if (isDashboardLoaded && !!dashboardId) {
      setParams((params) => ({
        ...params,
        name: dashboard?.name,
        section_id: dashboard?.section_id,
        dashboardId: dashboardId,
        minimum_amount: dashboard?.minimum_amount || '',
        maximum_amount: dashboard?.maximum_amount || '',
        vendors: dashboard?.vendors || [],
        employees: dashboard?.employees || [],
        analytics: dashboard?.analytics || [],
        orgs: dashboard?.account_search?.orgs || [],
        objects: dashboard?.account_search?.objects || [],
        projects: dashboard?.account_search?.projects || [],
        segOnes: dashboard?.account_search?.seg_ones || [],
        segTwos: dashboard?.account_search?.seg_twos || [],
        segThrees: dashboard?.account_search?.seg_threes || [],
        segFours: dashboard?.account_search?.seg_fours || [],
        segFives: dashboard?.account_search?.seg_fives || [],
        segSixes: dashboard?.account_search?.seg_sixes || [],
        segSevens: dashboard?.account_search?.seg_sevens || [],
        segEights: dashboard?.account_search?.seg_eights || [],
        bgAccountType: dashboard?.params?.bgAccountType || '',
        bgBudgetYear: dashboard?.params?.bgBudgetYear || '',
        bgMinBudgetPercentOfOriginal:
          dashboard?.params?.bgMinBudgetPercentOfOriginal || '',
        bgMaxBudgetPercentOfOriginal:
          dashboard?.params?.bgMaxBudgetPercentOfOriginal || '',
        bgMinBudgetPercentRemaining:
          dashboard?.params?.bgMinBudgetPercentRemaining || '',
        bgMaxBudgetPercentRemaining:
          dashboard?.params?.bgMaxBudgetPercentRemaining || '',
        bgAccountLastUpdateOption: dashboard?.params?.bgAccountLastUpdateOption || '',
        bgMinAccountLastUpdate: dashboard?.params?.bgMinAccountLastUpdate || '',
        bgMaxAccountLastUpdate: dashboard?.params?.bgMaxAccountLastUpdate || ''
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardLoaded, dashboardId, setParams])

  const dashboardSettings = {
    params: {
      bgAccountType: params.bgAccountType,
      bgBudgetYear: params.bgBudgetYear,
      bgMinBudgetPercentOfOriginal: params.bgMinBudgetPercentOfOriginal,
      bgMaxBudgetPercentOfOriginal: params.bgMaxBudgetPercentOfOriginal,
      bgMinBudgetPercentRemaining: params.bgMinBudgetPercentRemaining,
      bgMaxBudgetPercentRemaining: params.bgMaxBudgetPercentRemaining,
      bgAccountLastUpdateOption: params.bgAccountLastUpdateOption,
      bgMinAccountLastUpdate: params.bgMinAccountLastUpdate,
      bgMaxAccountLastUpdate: params.bgMaxAccountLastUpdate,
    }
  }
  const { mutate } = useUpdateDashboard({
    params: dashboardSettings,
    id: dashboardId
  })

  useEffect(() => {
    if (isDashboardLoaded && !!dashboardId) {
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.bgAccountType,
    params.bgBudgetYear,
    params.bgMinBudgetPercentOfOriginal,
    params.bgMaxBudgetPercentOfOriginal,
    params.bgMinBudgetPercentRemaining,
    params.bgMaxBudgetPercentRemaining,
    params.bgAccountLastUpdateOption,
    params.bgMinAccountLastUpdate,
    params.bgMaxAccountLastUpdate
  ])

  return (
    <Page
      title={
        <Row>
          <Col sm="auto">
            <SectionDropdown sectionId={sectionId} size="" />
            {false && isDashboardLoaded && (
              <DashboardTitle params={params} setParams={setParams} />
            )}
          </Col>
          <Col sm="auto"></Col>
        </Row>
      }
      pageTitle={`View - ${isDashboardLoaded ? dashboard?.name : ''}`}
    >
      <PageTitle />

      <Row className="my-4">
        <Col>
          <ErrorBoundary
            fallback={DefaultError}
            beforeCapture={(scope) => {
              scope.setTag('component', 'DashboardPopulation')
            }}
          >
            {false && (
              <DashboardPopulation
                params={params}
                setParams={setParams}
                sectionUnit={sectionUnit}
                sectionName={sectionName}
                isFilterOpen={isFilterOpen}
              />
            )}
          </ErrorBoundary>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <>
            <Row className="mb-3 border-bottom">
              <Col>
                {isDashboardsSuccess &&
                  dashboards
                    .slice(0, 6)
                    .map((d) => (
                      <Tab
                        active={d.id === dashboardId}
                        key={`tab-${d.id}`}
                        setDashboardId={setDashboardId}
                        dashboard={d}
                        isDashboardLoading={isDashboardLoading}
                        params={params}
                        setParams={setParams}
                      />
                    ))}
                <Button
                  className="py-2 px-3 border-left border-right border-light rounded-0 my-0"
                  variant="link"
                  style={{ backgroundColor: '#f7f7f7' }}
                  onClick={() => navigate('/dashboards')}
                >
                  All Views
                </Button>
                <div className="float-end">
                  <NewView sectionId={sectionId} sectionName={sectionName} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <DashboardFilters
                  params={params}
                  setParams={setParams}
                  isFilterOpen={isFilterOpen}
                  setIsFilterOpen={setIsFilterOpen}
                  sectionName={sectionName}
                  showStats={showStats}
                  setShowStats={setShowStats}
                  includedFilters={['year', 'accounts', 'analytics', 'more']}
                />
              </Col>
            </Row>
            {isSectionSuccess && (
              <>
                <Row className="mt-4">
                  <Col>
                    <ErrorBoundary
                      fallback={DefaultError}
                      beforeCapture={(scope) => {
                        scope.setTag('component', 'TopTransactions')
                      }}
                    >
                      {isDashboardLoaded && (
                        <TopTransactions
                          params={params}
                          sectionUnit={sectionUnit}
                          sectionName={sectionName}
                          dashboardId={dashboardId}
                          dashboard={dashboard}
                          isFilterOpen={isFilterOpen}
                        />
                      )}
                    </ErrorBoundary>
                  </Col>
                </Row>
              </>
            )}
          </>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default BgDashboard
