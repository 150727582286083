import React from 'react'
import NumberField from 'shared/forms/NumberField'

function MaxEntryToEffectiveDays({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Max Entry Date to GL Effective Date (Days)"
      showTitle={true}
      accessorKey="glMaxEntryToEffectiveDays"
    />
  )
}

export default MaxEntryToEffectiveDays
