import { useEffect, useState, useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Col from 'react-bootstrap/Col'
import UserContext from 'context/user-context'
import TopTransactions from '../top-transactions'
import Card from 'react-bootstrap/Card'
import Tab from 'dashboard/tab'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'
import useSections from 'api/hooks/use-sections'
import DashboardTitle from '../title'
import { useDashboard } from 'api/hooks/use-dashboards'
import DashboardFilters from '../filters'
import DashboardStats from '../stats'
import DashboardPopulation from '../population'
import TimeframeSelect from '../filters/timeframe-select'
import Button from 'react-bootstrap/Button'
import { useUpdateDashboard, useDashboards } from 'api/hooks/use-dashboards'
import { useNavigate } from 'react-router-dom'
import NewView from 'dashboard/new'
import SectionDropdown from 'dashboard/filters/module-dropdown'

function ApDashboard() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const id = urlParams.get('dashboard_id')
  const navigate = useNavigate()
  const { ap_dashboards } = useContext(UserContext)
  const dashboardUrlId = id || ap_dashboards[0].id

  useEffect(() => {
    if (!id) {
      const newUrl = `${window.location.pathname}?dashboard_id=${dashboardUrlId}`
      navigate(newUrl, { replace: true })
    }
  }, [id, dashboardUrlId, navigate])
  const [dashboardId, setDashboardId] = useState(dashboardUrlId)

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [showStats, setShowStats] = useState(true)
  const { isSuccess: isSectionSuccess, data: sections } = useSections({
    options: {}
  })

  const defaultSection = isSectionSuccess && sections[0]
  const options = {
    enabled: !!dashboardId && !isFilterOpen
  }

  const sectionId = ap_dashboards[0].section_id

  const { isSuccess: isDashboardsSuccess, data: dashboards } = useDashboards({
    params: { section_id: sectionId }
  })

  const {
    data: dashboard,
    isSuccess: isDashboardSuccess,
    isLoading: isDashboardLoading
  } = useDashboard({
    id: dashboardId,
    options
  })

  const defaultParams = {
    dashboardId: dashboardId,
    name: defaultSection.name || '',
    time_period: '30',
    risk_threshold: 0,
    section_id: defaultSection.id || '',
    minimum_amount: '',
    maximum_amount: '',
    vendors: [],
    employees: [],
    analytics: [],
    account_search: '',
    orgs: [],
    objects: [],
    projects: [],
    segOnes: [],
    segTwos: [],
    segThrees: [],
    segFours: [],
    segFives: [],
    segSixes: [],
    segSevens: [],
    segEights: [],
    apMinInvoiceToEntryDays: '',
    apMinEntryToPaymentDays: '',
    apMinTotalDaysToPayment: '',
    apMaxTotalDaysToPayment: '',
    apCheckType: ''
  }
  const [params, setParams] = useState({ ...defaultParams })

  const sectionName = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.name
    : ''
  const sectionUnit = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.unit
    : ''

  useEffect(() => {
    if (isDashboardSuccess && !!dashboardId) {
      setParams((params) => ({
        ...params,
        name: dashboard?.name,
        time_period: dashboard?.time_period,
        section_id: dashboard?.section_id,
        dashboardId: dashboardId,
        minimum_amount: dashboard?.minimum_amount || '',
        maximum_amount: dashboard?.maximum_amount || '',
        vendors: dashboard?.vendors || [],
        employees: dashboard?.employees || [],
        analytics: dashboard?.analytics || [],
        orgs: dashboard?.account_search?.orgs || [],
        objects: dashboard?.account_search?.objects || [],
        projects: dashboard?.account_search?.projects || [],
        segOnes: dashboard?.account_search?.seg_ones || [],
        segTwos: dashboard?.account_search?.seg_twos || [],
        segThrees: dashboard?.account_search?.seg_threes || [],
        segFours: dashboard?.account_search?.seg_fours || [],
        segFives: dashboard?.account_search?.seg_fives || [],
        segSixes: dashboard?.account_search?.seg_sixes || [],
        segSevens: dashboard?.account_search?.seg_sevens || [],
        segEights: dashboard?.account_search?.seg_eights || [],
        apMinInvoiceToEntryDays: dashboard?.params?.apMinInvoiceToEntryDays || 0,
        apMinEntryToPaymentDays: dashboard?.params?.apMinEntryToPaymentDays || 0,
        apMinTotalDaysToPayment: dashboard?.params?.apMinTotalDaysToPayment || 0,
        apMaxTotalDaysToPayment: dashboard?.params?.apMaxTotalDaysToPayment || 0,
        apCheckType: dashboard?.params?.apCheckType
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardSuccess, dashboardId, setParams])

  const dashboardSettings = {
    time_period: params.time_period,
    params: {
      apMinInvoiceToEntryDays: params.apMinInvoiceToEntryDays,
      apMinEntryToPaymentDays: params.apMinEntryToPaymentDays,
      apMinTotalDaysToPayment: params.apMinTotalDaysToPayment,
      apMaxTotalDaysToPayment: params.apMaxTotalDaysToPayment,
      apCheckType: params.apCheckType
    }
  }
  const { mutate } = useUpdateDashboard({
    params: dashboardSettings,
    id: dashboardId
  })

  useEffect(() => {
    if (isDashboardSuccess && !!dashboardId) {
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.time_period])

  return (
    <Page
      title={
        <Row>
          <Col sm="auto">
            <SectionDropdown sectionId={sectionId} size="" />
            {false && isDashboardSuccess && (
              <DashboardTitle params={params} setParams={setParams} />
            )}
          </Col>
          <Col sm="auto">
            <TimeframeSelect
              params={params}
              setParams={setParams}
              hideLabel={true}
            />
          </Col>
          <Col sm="auto"></Col>
        </Row>
      }
      pageTitle={`View - ${isDashboardSuccess ? dashboard?.name : ''}`}
    >
      <PageTitle />

      <Row className="my-4">
        <Col>
          <ErrorBoundary
            fallback={DefaultError}
            beforeCapture={(scope) => {
              scope.setTag('component', 'DashboardPopulation')
            }}
          >
            {false && (
              <DashboardPopulation
                params={params}
                setParams={setParams}
                sectionUnit={sectionUnit}
                sectionName={sectionName}
                isFilterOpen={isFilterOpen}
              />
            )}
          </ErrorBoundary>
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <>
            <Row className="mb-3 border-bottom">
              <Col>
                {isDashboardsSuccess &&
                  // limit to 7 dashboards
                  dashboards
                    .slice(0, 6)
                    .map((d) => (
                      <Tab
                        active={d.id === dashboardId}
                        key={`tab-${d.id}`}
                        setDashboardId={setDashboardId}
                        dashboard={d}
                        isDashboardLoading={isDashboardLoading}
                        params={params}
                        setParams={setParams}
                      />
                    ))}
                <Button
                  className="py-2 px-3 border-left border-right border-light rounded-0 my-0 "
                  style={{ backgroundColor: '#f7f7f7' }}
                  variant="link"
                  onClick={() => navigate('/dashboards')}
                >
                  All Views
                </Button>
                <div className="float-end">
                  <NewView sectionId={sectionId} sectionName={sectionName} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {isDashboardSuccess && (
                  <DashboardFilters
                    params={params}
                    setParams={setParams}
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                    sectionName={sectionName}
                    showStats={showStats}
                    setShowStats={setShowStats}
                  />
                )}
              </Col>
            </Row>
            {isSectionSuccess && (
              <>
                {showStats && (
                  <Row className="mt-4">
                    <Col>
                      <Row>
                        <ErrorBoundary
                          fallback={DefaultError}
                          beforeCapture={(scope) => {
                            scope.setTag('component', 'StatCard')
                          }}
                        >
                          <DashboardStats
                            params={params}
                            setParams={setParams}
                            sectionUnit={sectionUnit}
                            isFilterOpen={isFilterOpen}
                            sectionName={sectionName}
                            columns={[
                              { name: 'invoice_count', dataType: 'number' },
                              { name: 'invoice_amount', dataType: 'currency' },
                              {
                                name: 'avg_entry_to_payment',
                                dataType: 'number'
                              },
                              {
                                name: 'paid_past_due_percent',
                                dataType: 'percent'
                              }
                            ]}
                          />
                        </ErrorBoundary>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row className="mt-4">
                  <Col>
                    {isDashboardSuccess && (
                      <ErrorBoundary
                        fallback={DefaultError}
                        beforeCapture={(scope) => {
                          scope.setTag('component', 'TopTransactions')
                        }}
                      >
                        <TopTransactions
                          params={params}
                          setParams={setParams}
                          sectionUnit={sectionUnit}
                          sectionName={sectionName}
                          dashboardId={dashboardId}
                          columns={[
                            'view',
                            'vendor_name',
                            'risk_score',
                            'vendor_external_id',
                            'vendor_type',
                            'vendor_minority_code',
                            'invoice_amount',
                            'analytics_count',
                            'transaction_on',
                            'employee_name',
                            'invoice_number',
                            'invoice_to_entry_days',
                            'entry_to_payment_days',
                            'total_days_to_payment',
                            'po_number',
                            'invoice_date',
                            'invoice_entry_date',
                            'invoice_due_date',
                            'invoice_gl_effective_date',
                            'invoice_department_code',
                            'invoice_status',
                            'check_amount',
                            'check_void_amount',
                            'check_date',
                            'check_type',
                            'check_clear_date',
                            'check_date_to_clear_days'
                          ]}
                        />
                      </ErrorBoundary>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default ApDashboard
