import React, { useState, useContext } from 'react'
import PageTitle from 'layout/page-title'
import Page from 'layout/page'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TimeframeSelect from 'dashboard/filters/timeframe-select'
import Button from 'react-bootstrap/Button'
import EmployeeTab from './tabs/employee'
import AllWorkflows from './tabs/all'
import FilterContext from 'context/filters-context'
import PendingTab from './tabs/pending'

function Workflow() {
  const [activeTab, setActiveTab] = useState('all')
  const { filters } = useContext(FilterContext)
  const emptyState = {
    page: 1,
    perPage: 25,
    orderBy: 'action_at',
    orderDirection: 'desc',
    search: '',
    department_id: '',
    time_period: filters?.time_period || '30',
    hours: '',
    actions: [],
    processes: filters?.processes || [],
    numberOfActions: ''
  }
  const [params, setParams] = useState(emptyState)

  return (
    <Page
      pageTitle="Workflow"
      title={
        <Row>
          <Col sm="auto">Workflow</Col>
          <Col sm="auto">
            {activeTab !== 'pending' && (
              <TimeframeSelect
                params={params}
                setParams={setParams}
                hideLabel={true}
              />
            )}
          </Col>
        </Row>
      }
    >
      <PageTitle />

      <div className="mb-4">
        <Button
          variant={activeTab === 'all' ? 'primary' : 'light'}
          onClick={() => setActiveTab('all')}
          className="ms-2"
        >
          All Workflow Actions
        </Button>
        <Button
          variant={activeTab === 'employee' ? 'primary' : 'light'}
          onClick={() => setActiveTab('employee')}
          className="ms-2"
        >
          By Employee
        </Button>
        <Button
          variant={activeTab === 'pending' ? 'primary' : 'light'}
          onClick={() => setActiveTab('pending')}
          className="ms-2"
        >
          Pending Workflow Actions
        </Button>
      </div>

      {activeTab === 'all' && (
        <AllWorkflows params={params} setParams={setParams} />
      )}

      {activeTab === 'employee' && (
        <EmployeeTab params={params} setParams={setParams} />
      )}

      {activeTab === 'pending' && (
        <PendingTab />
      )}
    </Page>
  )
}

export default Workflow
