import React, { useState, useRef, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import smallDot from 'shared/small-dot.svg'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Form, InputGroup } from 'react-bootstrap'
import { useAnalytics } from 'api/hooks/use-analytics'
import { isEmpty, isNull } from 'lodash'
import CreateOrRemoveAnalytic from './create-or-remove-analytic'

function Analytics({ params, setParams, isFilterOpen, setIsFilterOpen }) {
  const [show, setShow] = useState(false)
  const analyticTarget = useRef(null)
  const clickableTarget = useRef(null)
  const [analyticSearch, setAnalyticSearch] = useState('')
  const handleClose = () => {
    setShow(false)
    setIsFilterOpen(false)
  }
  const handleShow = () => {
    setIsFilterOpen(true)
    setShow(true)
  }

  const options = {
    enabled: !isNull(analyticTarget.current)
  }
  const queryParams = {
    section_id: params.section_id || '',
    search: analyticSearch,
    per_page: 100
  }

  const {
    isSuccess,
    isLoading,
    data: analytics
  } = useAnalytics({ params: queryParams, options })

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        clickableTarget.current &&
        !clickableTarget.current.contains(event.target)
      ) {
        setIsFilterOpen(false)
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
  }, [analyticTarget, setShow, setIsFilterOpen])

  const isFiltered = params?.analytics?.length > 0
  const analyticCount = params?.analytics?.length

  return (
    <React.Fragment>
      <label className="mr-2 fw-light text-muted sr-only">
        <small>Analytics</small>
      </label>
      <div>
        <Button
          className={
            isFiltered ? 'bg-white text-primary' : 'bg-white text-info'
          }
          data-toggle="dropdown"
          ref={analyticTarget}
          onClick={() => handleShow()}
          style={{ border: isFiltered ? '' : '1px solid #ced4da' }}
          variant={isFiltered ? 'outline-primary' : 'outline-light'}
          aria-haspopup="true"
          aria-expanded="false"
        >
          {isFiltered ? (
            <>
              <img
                alt="Filter applied"
                src={smallDot}
                style={{ height: '10px', width: '10px' }}
              />
              {analyticCount} Analytics
            </>
          ) : (
            'Analytic'
          )}
        </Button>
      </div>
      <Overlay
        target={analyticTarget.current}
        ref={clickableTarget}
        show={show}
        placement="bottom-start"
      >
        <Popover
          id="popover-basic"
          style={{ maxHeight: 400, minWidth: '35%', overflowY: 'auto' }}
          
        >
          <Popover.Header className="sticky-top">
            <Row className="mt-2">
              <Col sm={8}>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>

                  <Form.Control
                    type="text"
                    placeholder="Search Analytics"
                    value={analyticSearch}
                    onChange={(e) => {
                      setParams({ ...params, page: 1 })
                      setAnalyticSearch(e.target.value)
                    }}
                  />
                </InputGroup>
              </Col>
              <Col sm={4}>
                <Button
                  variant="primary"
                  size="sm"
                  className="float-end mt-1"
                  onClick={() => handleClose()}
                >
                  Apply
                </Button>
                {!isEmpty(params.analytic_ids) ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end mt-1"
                    onClick={() => setParams({ ...params, analytic_ids: [] })}
                  >
                    Clear
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Popover.Header>
          <ListGroup variant="flush">
            {isSuccess &&
              analytics.data.map((analytic) => (
                <CreateOrRemoveAnalytic
                  key={`analytic-${analytic.id}`}
                  analytic={analytic}
                  params={params}
                  setParams={setParams}
                />
              ))}
            {isLoading && (
              <ListGroup.Item>
                <Spinner animation="grow" variant="warning" />
              </ListGroup.Item>
            )}
          </ListGroup>
        </Popover>
      </Overlay>
    </React.Fragment>
  )
}

export default Analytics
