import React from 'react'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Tooltip from 'react-bootstrap/Tooltip'

type StatusBadgeProps = {
  title: string,
  tooltipText: string,
  icon: IconProp
}

function StatusBadge({ title, tooltipText, icon }: StatusBadgeProps) {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{tooltipText}</Tooltip>}
    >
      <Badge
        pill
        bg="light"
        style={{ cursor: 'pointer' }}
        className="border border-dark text-primary"
      >
        {icon ? <FontAwesomeIcon icon={icon} /> : null} {title}
      </Badge>
    </OverlayTrigger>
  )
}

export default StatusBadge
