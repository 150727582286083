import React from 'react'
import Table from 'react-bootstrap/Table'
import TableFooter from 'shared/tables/table-footer'
import { useBusinessRules } from 'api/hooks/use-business-rules'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'react-bootstrap/Badge'
import TableLoading from 'shared/tables/table-loading'
import {
  faAddressCard,
  faUsers,
  faPaperPlane,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export default function BusinessRulesTable({ params, setParams }) {
  const { data, error, isLoading, isSuccess } = useBusinessRules({ params })
  const navigate = useNavigate()

  if (error) {
    return <div>Error loading business rules.</div>
  }

  const AssignedBadge = ({ rule }) => {
    const { assignable } = rule
    if (rule.assignable_type === 'Role') {
      return (
        <>
          {assignable.name} <FontAwesomeIcon icon={faAddressCard} />
        </>
      )
    } else if (rule.assignable_type === 'Employee') {
      return (
        <>
          {assignable.name} <FontAwesomeIcon icon={faUsers} />
        </>
      )
    }
    return <span className="badge badge-light">Unknown</span>
  }

  const DollarAmountBadge = ({ rule }) => {
    const { lower_amount, upper_amount } = rule
    const DEFAULT_MIN = '0.0'
    const DEFAULT_MAX = '999999999.99'

    let displayText

    if (lower_amount === DEFAULT_MIN && upper_amount === DEFAULT_MAX) {
      displayText = 'All $'
    } else if (lower_amount !== DEFAULT_MIN && upper_amount === DEFAULT_MAX) {
      displayText = `> $${lower_amount.toLocaleString()}`
    } else if (lower_amount === DEFAULT_MIN && upper_amount !== DEFAULT_MAX) {
      displayText = `< $${upper_amount.toLocaleString()}`
    } else {
      displayText = `$${lower_amount.toLocaleString()} - $${upper_amount.toLocaleString()}`
    }

    return (
      <Badge className="text-center ms-2 bg-light text-primary fw-normal">
        {` ${displayText}`}
      </Badge>
    )
  }

  return (
    <>
      <Table 
        responsive
        hover
        className="border shadow-sm bg-white"
        style={{ cursor: 'pointer' }}
      >
        <thead>
          <tr>
            <th>Rule #</th>
            <th>Assigned To</th>
            <th>Rules</th>
            <th>Process</th>
            <th>Rule Type</th>
            <th>Approval Type</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={7} />}
          {isSuccess &&
            data.data.length > 0 &&
            data.data.map((rule) => (
              <tr
                key={rule.external_id}
                className="border-right"
                // href={`/business-rules/${rule.id}`}
                onClick={() => navigate(`/business-rules/${rule.id}`)}
              >
                <td>{rule.external_id}</td>
                <td>
                  <AssignedBadge rule={rule} />
                </td>
                <td>
                  <div>
                    {rule.department && (
                      <Badge className="text-center ms-2 bg-light text-primary fw-normal">
                        Dept: {rule.department}
                      </Badge>
                    )}
                    {rule.location && (
                      <Badge className="text-center ms-2 bg-light text-primary fw-normal">
                        Loc: {rule.location}
                      </Badge>
                    )}
                    {rule.is_all_approvals && (
                      <Badge className="text-center ms-2 bg-light text-primary fw-normal">
                        All Approvals
                      </Badge>
                    )}
                    {rule.is_catch_all && (
                      <Badge className="text-center ms-2 bg-light text-primary fw-normal">
                        Catch All
                      </Badge>
                    )}
                    {rule.is_bypass_restart && (
                      <Badge className="text-center ms-2 bg-light text-primary fw-normal">
                        Bypass Restart
                      </Badge>
                    )}
                    <DollarAmountBadge rule={rule} />
                  </div>
                </td>
                <td>{rule.process_name}</td>
                <td>
                  {rule.rule_type === 'A' ? (
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} /> Approval
                    </span>
                  ) : (
                    <span>
                      <FontAwesomeIcon icon={faPaperPlane} /> Notification
                    </span>
                  )}
                </td>
                <td>{rule.approval_type}</td>
              </tr>
            ))}
          {isSuccess && data.data.length === 0 && (
            <tr>
              <td colSpan="14" className="text-center">
                <div className="mb-2 h6">No Business Rules Found</div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter meta={data.meta} params={params} setParams={setParams} />
      )}
    </>
  )
}
