import React from 'react'
import Datetime from 'react-datetime'

function EndOn({ params, setParams }) {
  return (
    <Datetime
      key={params.endOn}
      dateFormat="MM/DD/YYYY"
      timeFormat={false}
      inputProps={{
        placeholder: 'End Date',
        required: true,
        pattern: '(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}'
      }}
      onChange={(e) => setParams({ ...params, endOn: e })}
      value={params.endOn || ''}
    />
  )
}

export default EndOn
