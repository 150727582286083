import React from 'react'
import SelectField from 'shared/forms/SelectField'

function Period({ params, setParams }) {
  const types = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' }
  ]

  return (
    <SelectField
      params={params}
      setParams={setParams}
      options={types}
      accessorKey="glPeriod"
      title="Period"
      showTitle={true}
      placeholder="Select Period"
    />
  )
}

export default Period
