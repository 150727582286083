import React, { useState, useEffect } from 'react'
import { useCreateExport } from 'api/hooks/use-exports'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

function ExportWorkflow({params, setParams, source = 'workflow'}) {
  const [show, setShow] = useState(false)
  const defaultExportParams = {
    name: 'workflow',
    source: source,
    status: 'queued',
    params: params
  }
  const [exportParams, setExportParams] = useState(defaultExportParams)
  
  useEffect(() => {
    setExportParams(prevExportParams => ({
      ...prevExportParams,
      params: params
    }))
  }, [params.processes, params])
    
  const { mutate, isSuccess } = useCreateExport({
    params: exportParams
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success('File will be available in Exports shortly')
    }
  }, [isSuccess])

  const handleSubmit = () => {
    mutate(exportParams)
    setShow(false)
    setExportParams(defaultExportParams)
  }

  return (
    <React.Fragment>
      <button className="btn btn-light" onClick={() => setShow(true)}>
        <span className="text-primary">
          <FontAwesomeIcon icon={faDownload} className="me-1" /> Export
        </span>
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="lead fw-normal">
            <FontAwesomeIcon icon={faDownload} className="text-muted mx-2" />
            Export
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="text-muted fw-light">
              <small>Export Name</small>
            </Form.Label>
            <Form.Control
              type="text"
              value={exportParams.name}
              autoFocus
              onChange={(e) => setExportParams({ ...exportParams, name: e.target.value })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="bg-white text-muted"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default ExportWorkflow