import React from 'react'

const AmountFilter = ({ params, setParams }) => {
  const handleInputChange = (e) => {
    const newValue = e.target.value
    if (!isNaN(newValue)) {
      setParams({ ...params, amount: newValue })
    }
  }

  return (
    <div className="amount-filter">
      <input
        type="number"
        id="amount"
        className="form-control"
        value={params.amount || ''}
        onChange={handleInputChange}
        placeholder="Enter amount"
      />
    </div>
  )
}

export default AmountFilter
