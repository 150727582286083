import React, { useState } from 'react'
import Page from 'layout/sod/page'
import Button from 'react-bootstrap/Button'
import PageTitle from 'layout/page-title'
import ConflictsTab from './conflicts-tab'
import { split } from 'lodash'
import useEmployeeDuties from 'api/hooks/use-employee-duties'
import EmptyPage from 'layout/sod/empty-page'
import RolesTab from './roles-tab'
import PermissionsTab from './permissions-tab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import StatusBadge from 'shared/badges/status-badge'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons'
import Stats from './stats'
import AuditLogTab from './audit-log-tab'
import FunctionalCategories from './FunctionalCategories'
import BusinessRulesTab from './BusinessRulesTab'

function Duty() {
  const path = window.location.pathname
  const [activeTab, setActiveTab] = useState('conflicts')
  const dutyId = split(path, '/')[2]
  const [functionalModalShow, setFunctionalModalShow] = useState(false)
  const [activeKey, setActiveKey] = useState('')

  const params = {
    id: dutyId
  }

  const {
    isSuccess,
    isLoading,
    isError,
    data: employee
  } = useEmployeeDuties({ params })
  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Users', url: 'users' },
    { name: isSuccess ? employee.external_id : '', url: '' }
  ]

  if (isLoading) {
    return <EmptyPage />
  }

  return (
    <Page title={'User'}>
      <PageTitle breadcrumbs={crumbs} />
      <h4 className="mt-4">
        {employee.external_id}
        {employee.status === 'inactive' ? (
          <span className="ms-2">
            <StatusBadge
              title="Disabled"
              icon={faUserSlash}
              tooltipText="Account is not active"
            />
          </span>
        ) : null}
        <Button
          variant="light-outline"
          className="bg-white ms-2 border-secondary"
          onClick={() => setFunctionalModalShow(true)}
        >
          {Object.keys(employee.functional_categories || {}).length} Functional
          Categories
        </Button>
        {isSuccess && (
          <FunctionalCategories
            employee={employee}
            show={functionalModalShow}
            setShow={setFunctionalModalShow}
            setActiveTab={setActiveTab}
          />
        )}
        {/* TODO: SOD one-pager */}
        {false && (
          <Button
            variant="light-outline"
            className="bg-white ms-2 border-secondary"
            onClick={() =>
              toast.info('Holden will hand you fake PDF', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: true,
                progress: undefined,
                theme: 'light'
              })
            }
          >
            <FontAwesomeIcon icon={faFilePdf} className="me-2" />
            Export
          </Button>
        )}
      </h4>
      <hr />

      <div className="mb-4">{isSuccess && <Stats employee={employee} />}</div>

      <div className="mb-4">
        <Button
          variant={activeTab === 'conflicts' ? 'primary' : 'light'}
          onClick={() => setActiveTab('conflicts')}
          className="ms-2"
        >
          Conflicts
        </Button>
        <Button
          variant={activeTab === 'roles' ? 'primary' : 'light'}
          onClick={() => setActiveTab('roles')}
          className="ms-2"
        >
          Roles
        </Button>
        <Button
          variant={activeTab === 'permissions' ? 'primary' : 'light'}
          onClick={() => setActiveTab('permissions')}
          className="ms-2"
        >
          Permissions
        </Button>
        <Button
          variant={activeTab === 'audit-log' ? 'primary' : 'light'}
          onClick={() => setActiveTab('audit-log')}
          className="ms-2"
        >
          Audit Log
        </Button>
        <Button
          variant={activeTab === 'business-rules' ? 'primary' : 'light'}
          onClick={() => setActiveTab('business-rules')}
          className="ms-2"
        >
          Business Rules
        </Button>
      </div>

      {activeTab === 'conflicts' && (
        <ConflictsTab
          employee={employee}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          isSuccess={isSuccess}
        />
      )}
      {activeTab === 'roles' && (
        <RolesTab employee={employee} isError={isError} />
      )}
      {activeTab === 'permissions' && (
        <PermissionsTab
          employeeId={employee.id}
          categories={employee.functional_categories}
          isError={isError}
        />
      )}
      {activeTab === 'audit-log' && <AuditLogTab employeeId={employee.id} />}
      {activeTab === 'business-rules' && (
        <BusinessRulesTab employee={employee} />
      )}
    </Page>
  )
}

export default Duty
