import React from 'react'
import { useRoleConflict } from 'api/hooks/use-role-conflicts'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'

function ConflictDetails({ role, analytic, isActive }) {
  const params = {
    role_id: role.id,
    analytic_id: analytic.id
  }
  const options = {
    enabled: isActive
  }

  const { isSuccess, isLoading, data } = useRoleConflict({ params, options })

  const grouped = isSuccess
    ? data.conflicts.reduce((acc, conflict) => {
        const key = `${conflict.role}-${conflict.conflict_role}`

        if (!acc[key]) {
          acc[key] = {
            role: conflict.role,
            conflict_role: conflict.conflict_role,
            permissions: []
          }
        }

        acc[key].permissions.push({
          permission: conflict.permission,
          permission_description: conflict.permission_description,
          conflict_permission: conflict.conflict_permission,
          conflict_permission_description:
            conflict.conflict_permission_description
        })

        return acc
      }, {})
    : []

  // Convert the grouped object back into an array for easy rendering
  const groupedArray = Object.values(grouped)

  return (
    <div>
      <Table
        className="border-light border bg-white shadow-sm"
        responsive
        size="sm"
      >
        <thead>
          <tr>
            <th>Permission</th>
            <th>Conflicting Permission</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={2} />}
          {isSuccess &&
            groupedArray.map((group) => (
              <React.Fragment key={`${group.role}-${group.conflict_role}`}>
                {group.permissions.map((permission, index) => (
                  <tr key={`${group.role}-${group.conflict_role}-${index}`}>
                    <td className="text-muted">
                      {permission.permission_description} (
                      {permission.permission})
                    </td>
                    <td className="text-muted">
                      {permission.conflict_permission_description} (
                      {permission.conflict_permission})
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ConflictDetails
