import React from 'react'
import Form from 'react-bootstrap/Form'
import { usePermissionCategories } from 'api/hooks/use-permission-categories'

function Category({ params, setParams }) {
  const {
    isSuccess,
    data: categories
  } = usePermissionCategories({})

  return (
    <Form.Group>
      <Form.Select
        as="select"
        value={params.category_id}
        onChange={(e) =>
          setParams({
            ...params,
            category: e.target.value
          })
        }
      >
        <option value="">Select Caption</option>
        {isSuccess &&
          categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  )
}

export default Category
