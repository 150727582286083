import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ConflictDropdown from './conflict-dropdown'
import RoleDropdown from './role-dropdown'
import Search from 'shared/forms/search'
import DepartmentDropdown from 'shared/department-dropdown'
import TerminatedToggle from './terminated-toggle'
import InactiveUsersToggle from './inactive-users'
import InactiveRolesToggle from './inactive-roles'
import MissingPayroll from './missing-payroll'
import Category from 'duties/permissions/filters/category'

function Filters({ filterParams, setFilterParams, dataType = 'Role' }) {
  return (
    <>
      <Row className="mb-4 mt-2">
        <Col sm={2}>
          <Search params={filterParams} setParams={setFilterParams} />
        </Col>

        {dataType === 'Employee' && (
          <Col sm={2}>
            <DepartmentDropdown
              params={filterParams}
              setParams={setFilterParams}
            />
          </Col>
        )}
        {dataType === 'Employee' && (
          <Col sm={2}>
            <RoleDropdown
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          </Col>
        )}
        <Col sm={2}>
          <ConflictDropdown
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Col>
        {dataType === 'Role' && (
          <>
            <Col sm={2}>
              <Category params={filterParams} setParams={setFilterParams} />
            </Col>
            <Col sm={2}>
              <InactiveRolesToggle
                params={filterParams}
                setParams={setFilterParams}
              />
            </Col>
          </>
        )}
        {dataType === 'Employee' && (
          <>
            <Col sm="auto">
              <TerminatedToggle
                params={filterParams}
                setParams={setFilterParams}
              />
            </Col>
            <Col sm="auto">
              <InactiveUsersToggle
                params={filterParams}
                setParams={setFilterParams}
              />
            </Col>
            <Col sm="auto">
              <MissingPayroll
                params={filterParams}
                setParams={setFilterParams}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  )
}

export default Filters
