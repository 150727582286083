import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


function Stats({ role }) {
  const stats = [
    {
      name: 'Internal Conflicts',
      value: role.internal_conflicts_count
    },
    {
      name: 'Employees (Enabled)',
      value: role.employees_count
    },
    {
      name: 'High Risk Permissions',
      value: role.high_risk_permissions_count
    },
    {
      name: 'Super User Permissions',
      value: role.superuser_permissions_count
    }
  ]

  return (
    <>
      <Row>
        {stats.map((stat, index) => (
          <Col key={index} sm={3}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm="auto">
                    <h4>{stat.value}</h4>
                  </Col>
                </Row>

                <Card.Text className="text-muted">{stat.name}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Stats
