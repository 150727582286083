import React, { useEffect, useState } from 'react'
import useWorkflowProcessTypes from 'api/hooks/use-workflow-process-type'
import { Form } from 'react-bootstrap'

function ProcessType({ params, setParams }) {
  const [processType, setProcessType] = useState(params?.process_type_id || '')
  const { data: processTypes, isSuccess } = useWorkflowProcessTypes({})

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      process_type_id: processType
    }))
  }, [processType, setParams])

  return (
    <Form.Group controlId="processTypeSelect">
      <Form.Select
        as="select"
        value={processType}
        onChange={(e) => setProcessType(e.target.value)}
      >
        <option value="">Select a process type</option>
        {isSuccess &&
          processTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.code} - {type.name}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  )
}

export default ProcessType
