import React from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'
import ConflictBadge from '../badges/conflict'
import Badge from 'react-bootstrap/Badge'
import TableLoading from 'shared/tables/table-loading'
import TableFooter from 'shared/tables/table-footer'
import { useNavigate } from 'react-router-dom'
import useDuties from 'api/hooks/use-duties'
import SortArrow from 'shared/tables/sort-arrow'
import TableError from 'shared/tables/table-error'
import { faUserSlash, faUserMinus } from '@fortawesome/free-solid-svg-icons'
import StatusBadge from '../../shared/badges/status-badge'

function DutyTable({ filterParams, setFilterParams }) {
  const navigate = useNavigate()

  const updateOrdering = (column) => {
    const isCurrentColumn = filterParams.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setFilterParams({
      ...filterParams,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return filterParams.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  const {
    isSuccess,
    isLoading,
    isError,
    data: duties
  } = useDuties({ params: filterParams })

  const convertToDaysSince = (date) => {
    const date1 = new Date(date)
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }

  return (
    <>
      <Table
        responsive
        hover
        size="sm"
        className="border shadow-sm bg-white"
        style={{ cursor: 'pointer' }}
      >
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th colSpan={2} className="border-start text-center">
              Roles
            </th>
            <th colSpan={3} className="border-start text-center">
              Permissions
            </th>
          </tr>
          <tr>
            <th style={{ width: '25%' }} className="border-left border-right">
              Employee ID{' '}
            </th>
            <th
              style={{ width: '10%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering('employee_sod_counts.conflicts_count')
              }
            >
              Conflicts
              <SortArrow
                column="employee_sod_counts.conflicts_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              className="text-center border-start"
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering('employee_sod_counts.roles_with_conflicts_count')
              }
            >
              With Conflicts
              <SortArrow
                column="employee_sod_counts.roles_with_conflicts_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() => updateOrdering('employee_sod_counts.roles_count')}
            >
              Total
              <SortArrow
                column="employee_sod_counts.roles_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              className="text-center border-start"
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering(
                  'employee_sod_counts.high_risk_permissions_count'
                )
              }
            >
              High Risk
              <SortArrow
                column="employee_sod_counts.high_risk_permissions_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering(
                  'employee_sod_counts.superuser_permissions_count'
                )
              }
            >
              Super User
              <SortArrow
                column="employee_sod_counts.superuser_permissions_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() =>
                updateOrdering('employee_sod_counts.permissions_count')
              }
            >
              Total
              <SortArrow
                column="employee_sod_counts.permissions_count"
                orderBy={filterParams.orderBy}
                orderDirection={filterParams.orderDirection}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoading columns={6} />}
          {isError && <TableError columns={6} />}
          {isSuccess &&
            duties.data.map((d) => (
              <tr
                key={`duty-row-${d.id}`}
                className="border-right"
                onClick={() => navigate(`/users/${d.id}`)}
              >
                <td className="border-right border-left">
                  {d.external_id}
                  <small className="text-muted ms-2">{d.department_name}</small>
                  <span className="fw-light text-muted ms-2">{d.name}</span>
                  {d.rules_count > 0 && (
                    <Badge
                      pill
                      className={
                        'text-center fw-normal bg-light text-primary mx-1'
                      }
                    >
                      {d.rules_count} Business Rule(s)
                    </Badge>
                  )}
                  {d.status === 'inactive' ? (
                    <span className="ms-2">
                      <StatusBadge
                        title="Disabled"
                        icon={faUserSlash}
                        tooltipText="Account is not active"
                      />
                    </span>
                  ) : null}
                  {d.status === 'active' && d.is_terminated ? (
                    <span className="ms-2">
                      <StatusBadge
                        title="Terminated"
                        icon={faUserMinus}
                        tooltipText={`Employee account is active but employee has been terminated for ${convertToDaysSince(
                          d.terminated_on
                        )} days`}
                      />
                    </span>
                  ) : null}
                </td>
                <td className="text-center">
                  {d.conflicts_count > 0 && (
                    <ConflictBadge count={d.conflicts_count} />
                  )}
                </td>
                <td className="text-center text-muted border-start">
                  {d.roles_with_conflicts_count}
                </td>
                <td className="text-center text-muted">{d.roles_count}</td>
                <td className="text-center text-muted border-start">
                  {d.high_risk_permissions_count}
                </td>
                <td className="text-center text-muted">
                  {d.superuser_permissions_count}
                </td>
                <td className="text-center text-muted">
                  {d.permissions_count}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isSuccess && (
        <TableFooter
          meta={duties.meta}
          params={filterParams}
          setParams={setFilterParams}
        />
      )}
    </>
  )
}

DutyTable.propTypes = {
  filterParams: PropTypes.object.isRequired,
  setFilterParams: PropTypes.func.isRequired
}

export default DutyTable
