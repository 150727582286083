import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getBusinessRules = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/business_rules`

  const data = await apiGet({ url, token, params })
  return data
}

export function useBusinessRules({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryKey = ['business-rules', params]
  const queryFn = () => getBusinessRules(token, params, municipality_id)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getBusinessRule = async (token, params) => {
  const url = `business_rules/${params.id}`

  const data = await apiGet({ url, token, params })
  return data
}

export function useBusinessRule({ params, options }) {
  const token = useContext(AuthContext)
  const queryKey = ['business-rule', params]
  const queryFn = () => getBusinessRule(token, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
