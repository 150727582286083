import React from 'react'
import Table from 'duties/business_rules/Table'

function BusinessRulesTab({ role }) {
  const [params, setParams] = React.useState({
    page: 1,
    perPage: 10,
    assignable_id: role.id,
    assignable_type: 'Role'
  })
  return (
    <Table params={params} setParams={setParams} />
  )
}

export default BusinessRulesTab