import React from 'react'

const RuleNumber = ({ params, setParams }) => {
  const handleChange = (e) => {
    const value = e.target.value
    setParams({ ...params, rule_number: value })
  }

  return (
    <div>
      <input
        type="text"
        id="ruleNumber"
        className="form-control"
        placeholder="Rule #"
        value={params.rule_number || ''}
        onChange={handleChange}
      />
    </div>
  )
}

export default RuleNumber
