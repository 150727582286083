import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import useMunicipalityUsers from 'api/hooks/use-employee-options'

function UsersDropdown({ params, setParams, disabled = [] }) {
  const { isSuccess, isLoading, data: users } = useMunicipalityUsers({})
  const [selectedUser, setSelectedUser] = useState(params.changedById || '')

  useEffect(() => {
    setSelectedUser(params.changedById || '')
  }, [params.changedById])

  const updateParams = (value) => {
    setSelectedUser(value)
    setParams({ ...params, changedById: value })
  }

  return (
    <Form.Group>
      <Form.Select
        as="select"
        onChange={(e) => updateParams(e.target.value)}
        value={selectedUser}
      >
        <option value="">{isLoading ? 'Loading...' : 'Select User'}</option>
        {isSuccess &&
          users.map((u) => (
            <option
              value={u.id}
              key={`user-${u.id}`}
              disabled={disabled.includes(u.id)}
            >
              {u.full_name}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  )
}

export default UsersDropdown
