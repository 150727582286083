import React from 'react'
import ProcessType from './ProcessType'
import RuleType from './RuleType'
import AssignableType from './AssignableType'
import DepartmentCode from './DepartmentCode'
import Amount from './Amount'
import StepNumber from './StepNumber'
import RuleNumber from './RuleNumber'
import { Row, Col } from 'react-bootstrap'

function Filters({ params, setParams }) {
  return (
    <Row className="mb-3">
      <Col sm={1}>
        <RuleNumber params={params} setParams={setParams} />
      </Col>
      <Col sm={1}>
        <DepartmentCode params={params} setParams={setParams} />
      </Col>
      <Col sm="auto">
        <ProcessType params={params} setParams={setParams} />
      </Col>
      {false && (
        <>
          <Col sm="auto">
            <RuleType params={params} setParams={setParams} />
          </Col>
          <Col sm={1}>
            <StepNumber params={params} setParams={setParams} />
          </Col>
          <Col sm="auto">
            <AssignableType params={params} setParams={setParams} />
          </Col>
        </>
      )}

      <Col sm="auto">
        <Amount params={params} setParams={setParams} />
      </Col>
    </Row>
  )
}

export default Filters
