import React from 'react'
import NumberField from 'shared/forms/NumberField'

function MinEntryToEffectiveDays({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Entry Date to GL Effective Date (Days)"
      showTitle={true}
      accessorKey="glMinEntryToEffectiveDays"
    />
  )
}

export default MinEntryToEffectiveDays