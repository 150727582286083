import React, { useState } from 'react'
import { useSodAuditLogs } from 'api/hooks/use-sod-audit-log'
import RoleAssignment from 'duties/overview/audit-log/events/role-assignment'
import RoleMaster from 'duties/overview/audit-log/events/role-master'
import UserMaster from 'duties/overview/audit-log/events/user-master'
import RoleChange from 'duties/overview/audit-log/events/role-change'
import Spinner from 'react-bootstrap/Spinner'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Filters from './filters'

function AuditLog({ permissionId, roleId, userId }) {
  const defaultParams = {
    page: 1,
    perPage: 25,
    permissionId: permissionId || '',
    roleId: roleId || '',
    userId: userId || '',
    changeType: '',
    startOn: '',
    endOn: '',
    changedById: ''
  }
  const [params, setParams] = useState(defaultParams)
  const options = {
    keepPreviousData: true
  }
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useSodAuditLogs({ params, options })

  const updatePage = () => {
    setParams((prevParams) => ({
      ...prevParams,
      page: prevParams.page + 1
    }))
    fetchNextPage()
  }

  return (
    <Page title={'Segregation of Duties'}>
      <PageTitle />

      <h3>Audit Log</h3>

      <Row className="mb-3">
        <Col sm={12}>
          <Filters params={params} setParams={setParams} />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          {status === 'loading' && (
            <Spinner animation="grow" variant="warning" className="my-5" />
          )}
          {status === 'error' && <div>Error...</div>}
          {status === 'success' && data.pages.flat().length === 0 && (
            <div>No data</div>
          )}
          {status === 'success' && data.pages.flat().length > 0 && (
            <div className="list-group list-group-flush  border border-dark">
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={`page-${pageIndex}`}>
                  {page.map((log, index) => (
                    <React.Fragment key={`audit-log-index-${index}`}>
                      {log.change_type === 'Role Assignment' && (
                        <RoleAssignment log={log} />
                      )}
                      {log.change_type === 'Role Master' && (
                        <RoleMaster log={log} />
                      )}
                      {log.change_type === 'User Master' && (
                        <UserMaster log={log} />
                      )}
                      {![
                        'Role Master',
                        'User Master',
                        'Role Assignment'
                      ].includes(log.change_type) && <RoleChange log={log} />}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}

              {hasNextPage && !isFetchingNextPage && (
                <div className="text-center mt-4 mb-2">
                  <button
                    type="button"
                    className="btn btn-light text-primary"
                    onClick={updatePage}
                  >
                    Load More
                  </button>
                </div>
              )}
              {isFetchingNextPage && (
                <div className="text-center mt-4 mb-2">
                  <Spinner
                    animation="grow"
                    variant="warning"
                    className="my-5"
                  />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Page>
  )
}

export default AuditLog
