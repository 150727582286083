import React from 'react'
import NumberField from 'shared/forms/NumberField'

function MaxTotalDaysToPayment({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Max Invoice Date to Check Date (Days)"
      showTitle={true}
      accessorKey="apMaxTotalDaysToPayment"
    />
  )
}

export default MaxTotalDaysToPayment
