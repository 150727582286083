import React from 'react'
import { formatDateTime } from 'helpers/datetime'
import Link from './event-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faIdCard,
  faMinus,
  faWrench,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import LogEvent from './LogEvent'
import { accountStatus } from './text-helper'

function RoleMaster({ log }) {
  const LogIcon = () => {
    if (log.action === 'A') {
      return (
        <>
          <FontAwesomeIcon icon={faPlus} />
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    } else if (log.action === 'D') {
      return (
        <>
          <FontAwesomeIcon icon={faMinus} />
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faWrench} />
          <FontAwesomeIcon icon={faIdCard} className="mx-2" />
        </>
      )
    }
  }

  const actionText = (action) => {
    switch (action) {
      case 'A':
        return 'Created'
      case 'D':
        return 'Deleted'
      case 'U':
        return 'Updated'
      default:
        return action
    }
  }

  const LogText = () => {
    if (log.action === 'U') {
      if (log.field_name === 'role_isactive') {
        return (
          <>
            updated to:{' '}
            <span className="fw-bold">{accountStatus(log.new_value)}</span>
          </>
        )
      } else if (log.field_name === 'role_menu_mode') {
        return (
          <>
            Menus updated from <span className="fw-bold">{log.old_value}</span>{' '}
            to <span className="fw-bold">{log.new_value}</span>
          </>
        )
      } else {
        return (
          <>
            updated from <span className="fw-bold">{log.old_value}</span> to{' '}
            <span className="fw-bold">{log.new_value}</span>
          </>
        )
      }
    } else if (log.action === 'D') {
      return <>{actionText(log.action)}</>
    } else {
      return <>{actionText(log.action)}</>
    }
  }

  return (
    <LogEvent
      title={
        <>
          <LogIcon />
          Role {actionText(log.action)}{' '}
        </>
      }
      timestamp={`${formatDateTime(log.changed_at)} by ${
        log.changed_by.external_id
      }`}
    >
      <div className="fw-light text-muted">
        <Link linkObject={log.changeable} objectType="roles" /> <LogText />
      </div>
    </LogEvent>
  )
}

export default RoleMaster
