import React from 'react'
import Table from 'duties/business_rules/Table'

function BusinessRulesTab({ employee }) {
  const [params, setParams] = React.useState({
    page: 1,
    perPage: 10,
    assignable_id: employee.id,
    assignable_type: 'Employee'
  })
  return <Table params={params} setParams={setParams} />
}

export default BusinessRulesTab
