import React, { useState } from 'react'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import UserBadges from 'duties/employees/UserBadges'

function EmployeesTab({ permission }) {
  const [nonZeroValues, setNonZeroValues] = useState(true)

  let employees = nonZeroValues
    ? permission.employees.filter((e) => e.is_expansive)
    : permission.employees

  return (
    <Row>
      <Col sm={12}>
        <Card className="border">
          <Card.Header className="bg-white">
            <Row>
              <Col sm="auto">
                <div className="text-info">Employees</div>
              </Col>
              <Col sm="auto">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="show-non-zero-values"
                    onChange={(e) => setNonZeroValues(e.target.checked)}
                    checked={nonZeroValues}
                  />
                  <label
                    className="form-check-label"
                    for="show-non-zero-values"
                  >
                    Show "On" Values Only
                  </label>
                </div>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            {/* TODO: Consolidate Employee table */}
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Department</th>
                  <th>Value</th>
                  <th>From Role</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={`employee-${employee.external_id}`}>
                    <td>
                      {employee.external_id}
                      <UserBadges user={employee} />                      
                    </td>
                    <td>
                      <span className="ms-2 fw-light text-muted">
                        {employee.department_name}
                      </span>
                    </td>
                    <td>{employee.permission_value}</td>
                    <td>{employee.from_role}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default EmployeesTab
