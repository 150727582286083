import React from 'react'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from './Table'
import Filters from './filters'
import Alert from 'react-bootstrap/Alert'

function Duties() {
  const [params, setParams] = React.useState({
    page: 1,
    perPage: 10,
    process_type_id: '',
    rule_type_id: ''
  })

  return (
    <Page title={'Segregation of Duties'}>
      <PageTitle />

      <Alert variant="warning" className="my-2 py-1">
        <strong>Business Rules: </strong>
        If you do not see any business rules, please reach out to support@thirdline.io get an updated SQL
        package
      </Alert>

      <Row>
        <Col sm={12}>
          <Filters params={params} setParams={setParams} />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Table params={params} setParams={setParams} />
        </Col>
      </Row>
    </Page>
  )
}

export default Duties
