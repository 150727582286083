import React from 'react'

function LogEvent({title, timestamp, children}) {
  return (
    <li
      className="list-group-item d-flex flex-column justify-content-start align-items-start"
      aria-current="true"
    >
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center">
          {title}
        </div>
        <span className="text-muted fw-light ms-2">
          {timestamp}
        </span>
      </div>
      <div className="w-100 mt-1">
        {children}
      </div>
    </li>
  )
}

export default LogEvent