import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Search from 'shared/forms/search'
import Category from './category'

function Filters({ params, setParams, categories }) {
  return (
    <>
      <Row className="mb-4 mt-2">
        <Col sm={3}>
          <Search
            params={params}
            setParams={setParams}
            placeholder="Search Permissions"
          />
        </Col>

        <Col sm={2}>
          <Category params={params} setParams={setParams} />
        </Col>

        <Col sm="auto">
          <div className="form-check form-switch mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="show-high-risk-only"
              onChange={(e) =>
                setParams({ ...params, highRiskOnly: e.target.checked })
              }
              checked={params.highRiskOnly}
            />
            <label className="form-check-label" for="show-high-risk-only">
              Show High Risk Only
            </label>
          </div>
        </Col>
        <Col sm="auto">
          <div className="form-check form-switch mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="show-super-only"
              onChange={(e) =>
                setParams({ ...params, superUserOnly: e.target.checked })
              }
              checked={params.superUserOnly}
            />
            <label className="form-check-label" for="show-super-only">
              Show Super User Only
            </label>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Filters
