import { Form } from 'react-bootstrap'
import React from 'react'

function DepartmentCode({ params, setParams }) {
  const handleChange = (event) => {
    setParams({ ...params, department_code: event.target.value })
  }

  return (
    <Form.Group controlId="departmentInput">
      <Form.Control
        type="text"
        value={params.department_code || ''}
        onChange={handleChange}
        placeholder="Dept."
      />
    </Form.Group>
  )
}

export default DepartmentCode
