import React from 'react'
import { Row, Col, Table, Badge } from 'react-bootstrap'
import { useBusinessRule } from 'api/hooks/use-business-rules'
import { split } from 'lodash'
import Page from 'layout/sod/page'
import PageTitle from 'layout/page-title'

function BusinessRules() {
  const queryString = window.location.pathname
  const id = split(queryString, '/')[2]
  const params = { id: id }
  const { data: rule, isSuccess } = useBusinessRule({ params })

  const crumbs = [
    { name: 'Segregation of Duties', url: 'duties' },
    { name: 'Business Rules', url: 'business-rules' },
    { name: rule?.external_id , url: '' }
  ]

  return (
    <Page title={'Segregation of Duties'}>
      <PageTitle breadcrumbs={crumbs} />
      <Row>
        <Col>
          <h1>Business Rule Details</h1>
          <Table bordered>
            <tbody>
              {isSuccess && (
                <>
                  <tr>
                    <td>External ID</td>
                    <td>{rule.external_id}</td>
                  </tr>
                  <tr>
                    <td>Process</td>
                    <td>{rule.process}</td>
                  </tr>
                  <tr>
                    <td>Department</td>
                    <td>{rule.department}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{rule.location}</td>
                  </tr>
                  <tr>
                    <td>Assignable Type</td>
                    <td>{rule.assignable_type}</td>
                  </tr>
                  <tr>
                    <td>Assignable</td>
                    <td>{rule.assignable?.name}</td>
                  </tr>
                  <tr>
                    <td>Rule Type</td>
                    <td>{rule.rule_type}</td>
                  </tr>
                  <tr>
                    <td>Approval Type</td>
                    <td>{rule.approval_type}</td>
                  </tr>
                  <tr>
                    <td>Lower Amount</td>
                    <td>{rule.lower_amount}</td>
                  </tr>
                  <tr>
                    <td>Upper Amount</td>
                    <td>{rule.upper_amount}</td>
                  </tr>
                  {rule.is_all_approvals && (
                    <tr>
                      <td>Is All Approvals</td>
                      <td>
                        <Badge bg="success">Yes</Badge>
                      </td>
                    </tr>
                  )}
                  {rule.is_catch_all && (
                    <tr>
                      <td>Is Catch All</td>
                      <td>
                        <Badge bg="success">Yes</Badge>
                      </td>
                    </tr>
                  )}
                  {rule.is_bypass_restart && (
                    <tr>
                      <td>Is Bypass Restart</td>
                      <td>
                        <Badge bg="success">Yes</Badge>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Page>
  )
}

export default BusinessRules
