import { Form } from 'react-bootstrap'
import React from 'react'

function DepartmentCode({ params, setParams }) {
  const codes = ['HR', 'Finance', 'Engineering', 'Marketing']
  const handleChange = (event) => {
    setParams({ ...params, department: event.target.value })
  }

  return (
    <Form.Group controlId="departmentSelect">
      <Form.Select
        as="select"
        value={params.department || ''}
        onChange={handleChange}
      >
        <option value="">Select Department</option>
        {codes.map((code) => (
          <option key={code} value={code}>
            {code}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default DepartmentCode
