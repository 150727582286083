import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

function StartOn({ params, setParams }) {
  return (
    <Datetime
      key={params.startOn}
      inputProps={{
        placeholder: 'Start Date',
        required: true,
        pattern: '(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}',
        id: 'start-on'
      }}
      dateFormat="MM/DD/YYYY"
      timeFormat={false}
      onChange={(e) => setParams({ ...params, startOn: e })}
      value={params.startOn || ''}
    />
  )
}

export default StartOn
